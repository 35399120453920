import { ohr } from 'utils/axios'
import logger from 'utils/logger'
import resourcesPath from 'utils/resourcesPath'

const { ASSIGNMENTS, ASSIGNMENT_VIEWS } = resourcesPath

export const fetchAllAssignments = async () => {
    try {
        const { data } = await ohr.get(ASSIGNMENTS)
        return data
    } catch (error) {
        logger.error(error.message)
    }
}

export const updateAssignmentsList = async nextUrl => {
    let url = ASSIGNMENTS
    const query = nextUrl && nextUrl.split('?')[1]
    if (nextUrl) url = `${ASSIGNMENTS}?${query}`
    try {
        const { data } = await ohr.get(url)
        return data
    } catch (error) {
        logger.error(error.message)
    }
}

export const applyFilters = async query => {
    try {
        const { data } = await ohr.get(`${ASSIGNMENTS}${query}`)
        return data
    } catch (error) {
        logger.error(error.message)
    }
}

export const incrementViews = async assignmentId => {
    try {
        await ohr.patch(`${ASSIGNMENT_VIEWS}/${assignmentId}`)
    } catch (error) {
        logger.error(error.message)
    }
}
