import styled from 'styled-components'

export const MobileView = styled.div`
    @media (min-width: 992px) {
        width: 700px;
        border-left: 1px solid #ffffff2e;
        border-right: 1px solid #ffffff2e;
    }
`

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: ${props => props.theme.styles.body};
`

export const LoaderWrapper = styled.div`
    margin: 10px 0;
`
