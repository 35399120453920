import styled from 'styled-components'

import { shimmer } from 'animations/shimmer'

export const heightMap = {
    0: '170',
    1: '320',
    2: '437',
    3: '170',
    4: '320',
    5: '437',
    6: '170',
    7: '320',
    unit: 'px',
}

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
`

export const PreviewArea = styled.div`
    width: 90%;
    height: ${props => heightMap[props.count] + heightMap.unit};
    background: #4b4450;
    border-radius: 8px;
    margin: 2% 5%;
    ${shimmer}
`

export const TextPrimary = styled.div`
    width: 190px;
    height: 16px;
    background: rgba(255, 255, 255, 0.17);
    border-radius: 4px;
    margin: 2% 5%;
    ${shimmer}
`

export const TextSecondary = styled.div`
    width: 241px;
    height: 13px;
    background: rgba(255, 255, 255, 0.17);
    border-radius: 4px;
    margin: 2% 2%;
    ${shimmer}
`

export const SubWrapper = styled.div`
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: start;
    margin: 2% 5%;
`

export const AvatarWrapper = styled.div`
    width: 30px;
    height: 30px;
`
