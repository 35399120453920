import {
    fetchAllAssignments,
    updateAssignmentsList,
    applyFilters,
} from 'components/Assignments/apis'
import { fetchNewsletters } from 'pages/Newsletter/apis'
import logger from 'utils/logger'

import {
    _initializeAssignments,
    _upateAssignmentsList,
    _applyFilters,
    _isLoading,
    _getNewslettersDispatch,
} from '../dispatchers/assignments'

export const initializeAssignments = async () => {
    try {
        const assignments = await fetchAllAssignments()
        _initializeAssignments(assignments)
    } catch (error) {
        logger.error(error.message)
    } finally {
        _isLoading(false)
    }
}

export const fetchMoreAssignments = async query => {
    try {
        const assignments = await updateAssignmentsList(query)
        _upateAssignmentsList(assignments)
    } catch (error) {
        logger.error(error.message)
    } finally {
        _isLoading(false)
    }
}

export const applyAssignmentFilters = async query => {
    try {
        const assignments = await applyFilters(query)
        _applyFilters(assignments)
    } catch (error) {
        logger.error(error.message)
    } finally {
        _isLoading(false)
    }
}

export const getNewsletterAssignments = async () => {
    try {
        const assignments = await fetchNewsletters()
        _getNewslettersDispatch(assignments)
    } catch (error) {
        logger.error(error.message)
    } finally {
        _isLoading(false)
    }
}
