import { init, createReduxEnhancer, setTags } from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { deviceDetect } from 'react-device-detect'

import { identity } from 'helpers/identity'

import config from './config'
import logger from './logger'

const { REACT_APP_CUSTOM_NODE_ENV } = process.env

export const initialize = () => {
    /* get or set unique id */
    const uniqueId = identity.get() || identity.set()

    const blockSentry = ['DEVELOPMENT', 'STAGING', 'BETA'].includes(
        REACT_APP_CUSTOM_NODE_ENV
    )

    if (blockSentry) return
    /* initialize sentry */
    init({
        dsn:
            'https://5a6347f75f084c9ab4d264f685f66f54@o343131.ingest.sentry.io/5663171',
        release: `${config.app.name}@${config.app.version}`,
        environment: config.environment,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 0.5,
    })

    setTags({
        uniqueId,
    })

    /* log user device details */
    logger.info(JSON.stringify(deviceDetect()))
}

export const sentryReduxEnhancer = createReduxEnhancer({
    // Optionally pass options
})
