import React from 'react'

import Skeleton from 'components/Assignments/Assignment/components/Skeleton'
import ShimmerFilters from 'components/Filters/components/ShimmerFilters'

import { Container, HeaderShimmerAnim, SkeletonWrap } from './styles'

const SiteSkeleton = () => (
    <Container>
        <HeaderShimmerAnim />
        <ShimmerFilters />
        <SkeletonWrap>
            <Skeleton count={8} />
        </SkeletonWrap>
    </Container>
)

export default SiteSkeleton
