import React from 'react'

import FailureAnim from 'animations/Failure'
import SuccessAnim from 'animations/Success'
import Button, { btnSizeKeys } from 'components/common/Button'
import Text from 'components/common/Text'

import { PhoneLink, Container, Space } from './styles'

import * as SchedularStyle from '../../styles'

const StepThree = ({ closeModal, isSuccess }) => {
    const callScheduleStatus = isSuccess
        ? 'Your call is scheduled successfully'
        : 'We could not register your request'

    const reachOutText = isSuccess ? (
        'Looking forward to speaking with you'
    ) : (
        <>
            <Container>
                <Text bold={false} level="3">
                    We could not register your request
                </Text>
                <Text bold={false} level="3">
                    You can reach us at <b>+918861115000</b>
                </Text>
                <Text bold={false} level="3">
                    10am to 7pm{' '}
                </Text>
                <Text bold={false} level="3">
                    Monday to Friday
                </Text>
            </Container>
            <PhoneLink href="tel:9900990099">
                <Button size={btnSizeKeys.FREESIZE}>Reach us</Button>
            </PhoneLink>
        </>
    )

    setTimeout(() => {
        closeModal()
    }, 2500)

    return (
        <SchedularStyle.ThankyouContainer>
            <SchedularStyle.Container>
                {isSuccess ? <SuccessAnim /> : <FailureAnim />}
            </SchedularStyle.Container>
            <SchedularStyle.Header>
                <Text level="5">{callScheduleStatus}</Text>
                <Space>
                    <Text level="3" bold={false}>
                        {reachOutText}
                    </Text>
                </Space>
            </SchedularStyle.Header>
        </SchedularStyle.ThankyouContainer>
    )
}

export default StepThree
