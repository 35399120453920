import EventEmitter from 'eventemitter3'

export const createEventEmitter = () => {
    const eventEmitter = new EventEmitter()

    // freezing eventEmitter, so that later it won't get modified by any chance
    return Object.freeze({
        addListener: (event, func) => eventEmitter.addListener(event, func),
        removeListener: (event, func) =>
            eventEmitter.removeListener(event, func),
        once: (event, func) => eventEmitter.once(event, func),
        emit: (event, data) => eventEmitter.emit(event, data),
    })
}
