import React, { useState, useEffect, useCallback } from 'react'

import DefaultIcon from './Icon'
import { Container } from './styles'

export const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
}

const ScrollToTop = () => {
    const [showScroll, setShowScroll] = useState(false)
    // TODO: change libray for modal scrolling issue on ios
    const isModalOpen = document?.getElementById('custom-modal')

    const checkScrollTop = useCallback(() => {
        if (!showScroll && window.pageYOffset > 600) {
            setShowScroll(true)
        } else if (showScroll && window.pageYOffset <= 600) {
            setShowScroll(false)
        }
    }, [showScroll])

    useEffect(() => {
        window.addEventListener('scroll', checkScrollTop)
        return () => {
            window.removeEventListener('scroll', checkScrollTop)
        }
    }, [checkScrollTop])

    if (isModalOpen) return null
    return (
        showScroll && (
            <Container onClick={scrollTop}>
                <DefaultIcon />
            </Container>
        )
    )
}

export default ScrollToTop
