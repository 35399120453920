import { createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'

import { envTypes } from 'constants/environment'
import { sentryReduxEnhancer } from 'utils/sentry'

import rootReducer from './reducers'

const { STAGING, DEVELOPMENT } = envTypes
const { REACT_APP_CUSTOM_NODE_ENV } = process.env

const middlewares = [thunkMiddleware]

const showStore = [STAGING, DEVELOPMENT].includes(REACT_APP_CUSTOM_NODE_ENV)

const composeEnhancers =
    (showStore &&
        typeof window !== 'undefined' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose

const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(...middlewares), sentryReduxEnhancer)
)

export default store
