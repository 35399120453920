import { envTypes } from 'constants/environment'

const {
    REACT_APP_NAME,
    REACT_APP_VERSION,
    REACT_APP_CUSTOM_NODE_ENV,
    REACT_APP_OH_API_URL,
    REACT_APP_OH_ASSETS,
} = process.env

const assetsEnv = (REACT_APP_CUSTOM_NODE_ENV === envTypes.DEVELOPMENT
    ? envTypes.STAGING
    : REACT_APP_CUSTOM_NODE_ENV
).toLocaleLowerCase()

export default {
    app: {
        name: REACT_APP_NAME,
        version: REACT_APP_VERSION,
    },
    environment: REACT_APP_CUSTOM_NODE_ENV,
    ohApiUrl: REACT_APP_OH_API_URL,
    ohAssetsBase: REACT_APP_OH_ASSETS,
    ohPortfolioAssests: `${REACT_APP_OH_ASSETS}/portfolio/${assetsEnv}`,
}
