import React, { useEffect } from 'react'

import { emitModalStatus } from 'helpers/events/modal/emitter'

import { Wrapper, Container, Overlay } from './styles'

const CustomModal = ({ isOpen, onClose, children, style }) => {
    emitModalStatus(isOpen)
    useEffect(() => {
        const overflow = isOpen ? 'hidden' : 'auto'
        document.body.style.overflow = overflow
        return () => {
            document.body.style.overflow = 'auto'
        }
    }, [isOpen])
    return (
        isOpen && (
            <Wrapper id="custom-modal">
                <Overlay onClick={onClose} className="custom-modal-overlay" />
                <Container className="custom-modal-content" style={style}>
                    {children}
                </Container>
            </Wrapper>
        )
    )
}

export default CustomModal
