import { ohr } from 'utils/axios'
import logger from 'utils/logger'
import resourcesPath from 'utils/resourcesPath'

const { NEWSLETTER_URL, NEWSLETTER_SUBSCRIBER } = resourcesPath

export const fetchNewsletters = async () => {
    try {
        const {
            data: { results = [] },
        } = await ohr.get(NEWSLETTER_URL)
        return !!results.length && results[0]
    } catch (error) {
        logger.error(error.message)
    }
}

export const subscribeNewsletter = async data => {
    try {
        const response = await ohr.post(NEWSLETTER_SUBSCRIBER, data)
        return response.data
    } catch (error) {
        logger.error(error.message)
        throw error
    }
}

export const hasAlreadySubscribed = async phone => {
    try {
        const response = await ohr.get(
            `${NEWSLETTER_SUBSCRIBER}?phone_number=${phone}`
        )
        const count = response.data?.count
        return count && count > 0
    } catch (error) {
        logger.error(error.message)
        throw error
    }
}
