import jwtDecode from 'jwt-decode'

import { userTypes } from 'constants/users'
import Cookie from 'utils/cookie'

import { TOKEN_ENV_COOKIE } from './token'

export const getCookie = key => Cookie.get(key)

export const decodeJWT = token => {
    try {
        return jwtDecode(token)
    } catch (error) {
        console.log('invalid input :- ', error)
    }
}

export const isExpired = expDate => {
    if (!expDate) return true
    return Date.now() >= expDate * 1000
}

export const isTokenExpired = accessToken => {
    try {
        const decodedToken = decodeJWT(accessToken)
        if (decodedToken) return isExpired(decodedToken?.exp)
    } catch (error) {
        console.log('invalid input :- ', error)
    }
}

const getUserRoles = accessToken => {
    if (accessToken) {
        const {
            realm_access: { roles },
        } = jwtDecode(accessToken)
        return roles
    }
}

export const getUserTypeFromToken = accessToken => {
    if (accessToken) {
        const roles = getUserRoles(accessToken)
        if (roles.includes(userTypes.TEACHER)) return userTypes.TEACHER
        return userTypes.STUDENT
    }

    return userTypes.NONE
}

export const isLoggedIn = () => !!getCookie(TOKEN_ENV_COOKIE)

export const getUserId = async accessToken => {
    const token = getCookie(TOKEN_ENV_COOKIE)
    if (accessToken || token) {
        const { sub } = await decodeJWT(accessToken || token)
        return sub
    }
}

export const getUser = () => {
    const token = getCookie(TOKEN_ENV_COOKIE)
    if (token) {
        const user = decodeJWT(token)
        return user
    } else {
        return null
    }
}

export const getInitials = str => {
    if (str) {
        const name = str.split(' ')
        const initials = name.shift().charAt(0) + name.pop().charAt(0)
        return initials.toUpperCase()
    }
}
