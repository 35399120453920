import { assignmentFilterKeys } from 'constants/apiFilterkeys'

const { SUBJECTS, CLUBS } = assignmentFilterKeys

const getSubjectAndClubsQuery = (query, clubs) => {
    // split the query at '=' and then split again at every comma ','
    const subjectQuery = query.split('=')[1].split(',')
    // filter out clubs uuid from the query
    const clubs_uuids = clubs
        .filter(({ uuid }) => subjectQuery.includes(uuid))
        .map(({ uuid }) => uuid)
    // filter out subjects uuid from the query
    const subject_uuids = subjectQuery.filter(
        uuid => !clubs_uuids.includes(uuid)
    )

    let queryString = ''
    if (subject_uuids.length) {
        queryString += `?${SUBJECTS}=${subject_uuids.join()}`
    }
    // if any club has been selected, append it to the query string
    if (clubs_uuids.length) {
        queryString += `?${CLUBS}=${clubs_uuids.join()}`
    }
    return queryString
}

export const createFiltersQueryString = (filters, clubs) => {
    let query = ''
    let index = 0
    if (filters) {
        Object.keys(filters).filter(key => {
            if (filters[key].length) {
                const uuids = filters[key].map(uuid => uuid).join()
                // create the query string based on selected filter key & corresponding uuid
                const queryKey = assignmentFilterKeys[key.toUpperCase()]

                const individualQuery = `?${queryKey}=${uuids}`

                if (queryKey.includes('subject')) {
                    query += getSubjectAndClubsQuery(individualQuery, clubs)
                } else {
                    query += individualQuery
                }
            }
            return null
        })
        // this will replace all the '?' except the first to '&'
        return query.replace(/\?/g, item => (!index++ ? item : '&'))
    }
}
