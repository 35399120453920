import styled from 'styled-components'

export const Container = styled.div`
    svg {
        width: 100%;
        display: block;
        margin: 40px auto 0;
    }
    .path {
        stroke-dasharray: 1000;
        stroke-dashoffset: 0;
    }
`
