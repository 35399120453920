import PropTypes from 'prop-types'
import React from 'react'

import Loader from 'components/common/Loader'
import Text from 'components/common/Text'

import { btnSizeKeys, btnTypes } from './constants'
import { Wrapper } from './styles'

const Button = props => {
    const { text, isLoading, children } = props

    const content = isLoading ? (
        <Loader size="sm" />
    ) : (
        <Text level="3">{text || children}</Text>
    )

    return <Wrapper {...props}>{content}</Wrapper>
}

Button.propTypes = {
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    text: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.any, // pass either text or children. text has higher priority
    type: PropTypes.oneOf(Object.values(btnTypes)),
    size: PropTypes.oneOf(Object.values(btnSizeKeys)),
}

Button.defaultProps = {
    disabled: false,
    isLoading: false,
    text: '',
    children: null,
    onClick: () => {},
    type: btnTypes.PRIMARY,
    size: btnSizeKeys.MEDIUM,
}

export default Button

export { btnSizeKeys, btnTypes }
