import {
    SET_CLUBS,
    ADD_FILTER,
    REMOVE_FILTER,
    INITIALIZE_FILTERS,
    UPDATE_SELECTED_FILTERS,
    SET_SEARCH_TEXT,
} from '../types/filters'

export const initializeFilters = payload => ({
    type: INITIALIZE_FILTERS,
    payload,
})

export const setClubs = payload => ({
    type: SET_CLUBS,
    payload,
})

export const addFilter = (key, val) => ({
    type: ADD_FILTER,
    payload: { key, val },
})

export const removeFilter = (key, val) => ({
    type: REMOVE_FILTER,
    payload: { key, val },
})

export const updateSelectedFilters = payload => ({
    type: UPDATE_SELECTED_FILTERS,
    payload,
})

export const setSearchText = payload => ({
    type: SET_SEARCH_TEXT,
    payload,
})
