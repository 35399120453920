import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { NEWSLETTER } from 'constants/pages'
import { isPathname } from 'helpers/miscellaneous'
import { schoolPageHelper } from 'helpers/storage'
import * as assignmentSelectors from 'store/selectors/assignments'
import * as filterSelectors from 'store/selectors/filters'

const useSiteLoading = () => {
    const [hasLoaded, setLoaded] = useState(false)
    const assignments = useSelector(assignmentSelectors._data)
    const filters = useSelector(filterSelectors._data)
    const isProfilePage = isPathname('profile')
    const isNewsletterPage = isPathname(NEWSLETTER)
    const isSchoolPage = schoolPageHelper.get()
    // only check for filters for school or profile page
    const showLoader = isSchoolPage || isProfilePage || isNewsletterPage

    useEffect(() => {
        if (
            isNewsletterPage ||
            (showLoader && filters) ||
            (assignments && filters)
        ) {
            setLoaded(true)
            return
        }
        setLoaded(false)
    }, [assignments, filters, showLoader, isNewsletterPage])

    return hasLoaded
}

export default useSiteLoading
