import Cookie from 'js-cookie'

import { isDevelopment } from 'helpers/miscellaneous'

export const DOMAIN = isDevelopment
    ? window.location.hostname
    : '.openhouse.study'

Cookie.defaults.domain = DOMAIN
Cookie.defaults.expires = 15 // days
Cookie.defaults.sameSite = 'Lax'
Cookie.defaults.secure = !isDevelopment

export default Cookie
