import { sleep } from 'helpers/miscellaneous'
import * as user from 'store/actions/user'
import {
    initiateLoginProcess,
    refreshToken,
    authenticateUserLogin,
    handleErrorInUserAuth,
    removeUserCookies,
} from 'store/thunks/user'

import store from '..'

const { dispatch } = store

export const _initiateLoginProcess = payload =>
    dispatch(initiateLoginProcess(payload))

export const _setUserInfo = ({
    type,
    accessToken,
    refreshToken,
    sub: id,
    name: fullName,
    preferred_username: phone,
    standards,
    boards,
}) => {
    dispatch(user.setTokens({ accessToken, refreshToken }))
    dispatch(user.setUserType(type))
    dispatch(user.setAuth(true))
    dispatch(
        user.setUserInfo({
            phone,
            fullName,
            id,
            standards,
            boards,
        })
    )
}

export const _authenticateUserLogin = () => dispatch(authenticateUserLogin())

export const _removeUserCookies = () => {
    dispatch(user.removeCookies())
    dispatch(removeUserCookies())
}

export const _initiateRefreshToken = () => dispatch(refreshToken())

export const _handleErrorInUserAuth = () => dispatch(handleErrorInUserAuth())

export const _logout = async () => {
    await sleep(1500)
    dispatch(user.logout())
    _removeUserCookies()
}
