import PropTypes from 'prop-types'
import React from 'react'

import { schoolPageHelper } from 'helpers/storage'

import { textLevels } from './helpers/size'
import { SyledText } from './styles'

const Text = props => {
    const isSchoolPage = schoolPageHelper.get()
    return (
        <SyledText {...props} isSchoolPage={isSchoolPage}>
            {props.children}
        </SyledText>
    )
}

Text.propTypes = {
    bold: PropTypes.bool,
    color: PropTypes.string,
    level: PropTypes.oneOf(Object.values(textLevels)),
}

Text.defaultProps = {
    bold: true,
    color: '',
    level: textLevels[1],
}

export default Text
