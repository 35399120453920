import styled from 'styled-components'

export const Container = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
        margin: 15px 0;
    }
    span {
        margin: 35px 0;
        width: 80%;
        text-align: center;
    }
    button {
        height: 45px;
        width: 80%;
        margin-top: 35px;
    }
    a {
        text-decoration: none;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`

export const Image = styled.img``
