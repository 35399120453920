import styled, { css } from 'styled-components'

import { shimmer } from 'animations/shimmer'

const freeSize = `width: 100%; height: 100%;`

const commonStyles = css`
    position: absolute;
    border-radius: inherit;
    z-index: 2;
`

export const Wrapper = styled.div`
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    background: #828282;
    z-index: 1;
    ${freeSize}
`

export const Image = styled.img`
    ${props => !props.freeSize && commonStyles}
    ${freeSize}
    object-fit:cover;
`

export const Svg = styled.svg`
    ${props => !props.freeSize && commonStyles}
    ${freeSize}
    fill: none;
`

export const ShimmerAnim = styled.div`
    height: 100%;
    width: 100%;
    position: absolute;
    ${shimmer}
`
