import styled from 'styled-components'

export const Container = styled.div`
    position: fixed;
    z-index: 4;
    width: 24px;
    height: 24px;
    background: ${props => props.theme.styles.scrollToTop.background};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 50%;
    bottom: 5%;
    right: 10%;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    img {
        width: 15px;
    }
    @media (min-width: 992px) {
        bottom: 6%;
        right: 28%;
        width: 45px;
        height: 45px;
    }
`
