import PropTypes from 'prop-types'
import React from 'react'

import { loaderSizeKeys } from './constant'
import { Spinner } from './styles'

const Loader = props => (
    <Spinner viewBox="0 0 50 50" {...props}>
        <circle cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
    </Spinner>
)

Loader.defaultProps = {
    size: loaderSizeKeys.SMALL,
}

Loader.propTypes = {
    size: PropTypes.string,
}

export default Loader
