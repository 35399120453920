import {
    setClubs,
    addFilter,
    removeFilter,
    initializeFilters,
    updateSelectedFilters,
    setSearchText,
} from 'store/actions/filters'

import store from '..'

const { dispatch } = store

export const _initializeFilters = payload =>
    dispatch(initializeFilters(payload))

export const _addFilter = (key, val) => dispatch(addFilter(key, val))

export const _setClubs = payload => dispatch(setClubs(payload))

export const _removeFilter = (key, val) => dispatch(removeFilter(key, val))

export const _setSearchText = val => dispatch(setSearchText(val))

export const _updateSelectedFilters = payload =>
    dispatch(updateSelectedFilters(payload))
