import { userTypes } from 'constants/users'

import * as actionTypes from '../types/user'

const initialState = {
    authenticated: false,
    type: userTypes.NONE,
    userInfo: {
        phone: '',
        fullName: '',
        avatar: '',
        id: '',
        gender: '',
        boards: null,
        standards: null,
    },
    tokens: {
        accessToken: '',
        refreshToken: '',
    },
}

export const setUserAuthenticated = (state, payload) => ({
    ...state,
    authenticated: payload,
})

export const setUserType = (state, payload) => ({
    ...state,
    type: payload,
})

export const setUserInfo = (state, payload) => ({
    ...state,
    userInfo: {
        ...state.userInfo,
        ...payload,
    },
})

export const setAuthTokens = (state, payload) => ({
    ...state,
    tokens: payload,
})

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_USER_AUTH_TOKENS:
            return setAuthTokens(state, action.payload)
        case actionTypes.SET_USER_INFO:
            return setUserInfo(state, action.payload)
        case actionTypes.SET_USER_AUTENTICATED:
            return setUserAuthenticated(state, action.payload)
        case actionTypes.SET_USER_TYPE:
            return setUserType(state, action.payload)
        case actionTypes.LOGOUT:
        case actionTypes.REMOVE_COOKIE:
            return initialState
        default:
            return state
    }
}

export default userReducer
