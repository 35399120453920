import { fetchFilters } from 'components/Filters/apis'
import logger from 'utils/logger'

import { _initializeFilters, _setClubs } from '../dispatchers'

/**
 *
 * @param {object} filters
 * @desc
 * The purpose of this function is to first remove the clubs entity from the root level of filters data
 * and than append the clubs inside of subjects as the first element.
 *
 * initial API response:
 *
 * filters: {
 *  data: {
 *      classes:[ {classes_data} ],
 *      subjects:[ {subejcts_data} ],
 *      categories:[ {categories_data} ],
 *      clubs:[ {clubs_data} ],
 *   }
 * }
 *
 * formatted response:
 *
 * filters: {
 *  data: {
 *      classes:[ {classes_data} ],
 *      subjects:[ {clubs_data}, {subejcts_data} ],
 *      categories:[ {categories_data} ],
 *   }
 * }
 *
 * @returns {object}
 *
 */

const combineSubjtectsAndClubs = filters => {
    const clubsObj = {
        name: 'Clubs',
        uuid: 'clubs_list',
        items: [...filters.clubs],
    }
    return Object.keys(filters)
        .filter(key => key !== 'clubs')
        .reduce((obj, key) => {
            if (key === 'subjects') {
                obj[key] = [clubsObj, ...filters[key]]
            } else {
                obj[key] = filters[key]
            }
            return obj
        }, {})
}

export const initializeFilters = async () => {
    try {
        const filters = await fetchFilters()

        const formattedFilters = combineSubjtectsAndClubs(filters)

        _setClubs(filters?.clubs)
        _initializeFilters(formattedFilters)
    } catch (error) {
        logger.error(error.message)
    }
}
