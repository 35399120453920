export const textLevels = {
    '1': '1',
    '2': '2',
    '3': '3',
    '4': '4',
    '5': '5',
    '6': '6',
}

export const mobileFontSizes = {
    [textLevels[1]]: 12,
    [textLevels[2]]: 14,
    [textLevels[3]]: 16,
    [textLevels[4]]: 20,
    [textLevels[5]]: 24,
    [textLevels[6]]: 28,
    unit: 'px',
}
