import { useLocation, useHistory } from 'react-router-dom'

const usePageHistory = () => {
    const { pathname, state } = useLocation() || {}
    const { goBack, push } = useHistory()

    const navigate = (path, queries) =>
        push(path, { from: pathname, ...queries })

    const isHomepage = pathname === '/'
    const from = isHomepage ? pathname : state?.from
    return {
        currentPath: pathname,
        state,
        from,
        goBack,
        navigate,
    }
}

export default usePageHistory
