import {
    INITIALIZE_ASSIGNMENTS,
    UPDATE_ASSIGNMENTS_LIST,
    APPLY_ASSIGNMENT_FILTERS,
    SET_IS_FETCHING_ASSIGNMENTS,
    GET_NEWSLETTERS,
} from '../types/assignments'

const initialState = {
    data: null,
    newsletterData: null,
    isLoading: true,
}

const AssignmentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case INITIALIZE_ASSIGNMENTS:
            return {
                ...state,
                data: action.payload,
            }

        case UPDATE_ASSIGNMENTS_LIST:
            const results = action.payload
                ? [...state.data?.results, ...action.payload.results]
                : []
            return {
                data: {
                    ...state.data,
                    ...action.payload,
                    results,
                },
            }

        case APPLY_ASSIGNMENT_FILTERS:
            const appliedFiltersData = action.payload
                ? action.payload.results
                : []
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.payload,
                    results: appliedFiltersData,
                },
            }

        case GET_NEWSLETTERS:
            return {
                ...state,
                newsletterData: action.payload,
            }

        case SET_IS_FETCHING_ASSIGNMENTS:
            return {
                ...state,
                isLoading: action.payload,
            }
        default:
            return state
    }
}

export default AssignmentsReducer
