import styled from 'styled-components'

import { mobileFontSizes } from './helpers/size'

const getFontFamily = props => {
    const { isSchoolPage, bold } = props
    if (isSchoolPage && bold) return 'NunitoBold'
    if (isSchoolPage) return 'NunitoSans'
    if (bold) return 'AvenirBold'
    return 'inherit'
}

export const SyledText = styled.span`
    ${props => {
        const color = props.color || props.theme.styles.color
        const size = mobileFontSizes[props.level]
        const unit = mobileFontSizes.unit
        const fontSize = `${size}${unit}`
        // TODO: Change global font to 'Nunito sans' post release
        const fontFamily = getFontFamily(props)

        return `
            color: ${color};
            font-size: ${fontSize};
            font-family: ${fontFamily};
        `
    }}
`
