import jwtDecode from 'jwt-decode'

import {
    accessTokenHelper,
    refreshTokenHelper,
    userTypeHelper,
} from 'helpers/token'
import { getUserTypeFromToken, isExpired } from 'helpers/user'
import {
    _handleErrorInUserAuth,
    _initiateLoginProcess,
    _initiateRefreshToken,
    _removeUserCookies,
    _setUserInfo,
} from 'store/dispatchers/user'
import { ohr } from 'utils/axios'
import logger from 'utils/logger'
import resourcesPath from 'utils/resourcesPath'

const { CREATE_USER, REFRESH_TOKEN, USER } = resourcesPath

export const getUser = async userId => {
    try {
        const { data } = await ohr.get(`${USER}/${userId}/`)
        return data
    } catch (error) {
        logger.error(error.message)
    }
}

export const registerUser = async payload => ohr.post(CREATE_USER, payload)

export const initiateLoginProcess = data => () => {
    const { access_token, refresh_token, standards, boards } = data
    const { preferred_username, name, sub } = jwtDecode(access_token)

    const type = getUserTypeFromToken(access_token)
    // remove any previously stored token in cookies
    _removeUserCookies()
    // set the new token in cookies
    accessTokenHelper.set(access_token)
    refreshTokenHelper.set(refresh_token)
    userTypeHelper.set(type)

    const payload = {
        preferred_username,
        name,
        accessToken: access_token,
        refreshToken: refresh_token,
        type,
        sub, // user id
        standards,
        boards,
    }

    // dispatch actions to store user info to store
    _setUserInfo(payload)
}

export const authenticateUserLogin = () => () => {
    const accessToken = accessTokenHelper.get()
    const refreshToken = refreshTokenHelper.get()

    const hasToken = accessToken && refreshToken
    if (hasToken) {
        try {
            const { preferred_username, name, exp, sub } = jwtDecode(
                accessToken || ''
            )
            const type = getUserTypeFromToken(accessToken)
            const expired = isExpired(exp)
            if (expired) {
                const { exp: refreshTokenExp } = jwtDecode(refreshToken || '')
                const refreshTokenExpired = isExpired(refreshTokenExp)
                if (!refreshTokenExpired) {
                    _initiateRefreshToken()
                    return
                }
                _handleErrorInUserAuth()
                return
            }

            const payload = {
                preferred_username,
                name,
                accessToken,
                refreshToken,
                type,
                sub, // user id
            }
            _setUserInfo(payload)
        } catch (e) {
            _handleErrorInUserAuth(e)
        }
    } else {
        _removeUserCookies()
    }
}

export const refreshToken = () => async () => {
    try {
        const refresh_token = refreshTokenHelper.get()
        if (refresh_token) {
            _removeUserCookies()
            const { data } = await ohr.post(REFRESH_TOKEN, {
                refresh_token,
            })
            if (data && data.success) {
                _initiateLoginProcess(data.data)
                return
            }
        }
        _handleErrorInUserAuth()
    } catch (e) {
        _handleErrorInUserAuth(e)
    }
}

export const handleErrorInUserAuth = () => () => {
    // TODO: show error message
    _removeUserCookies()
}

export const removeUserCookies = () => () => {
    accessTokenHelper.del()
    refreshTokenHelper.del()
    userTypeHelper.del()
}
