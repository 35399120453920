import PropTypes from 'prop-types'
import React from 'react'

import { genderTypes, userTypes } from 'constants/users'
import { xmlnsUrl } from 'svgComponents/constants'

import { teacher } from './defaults'
import { ohAvatar } from './ohAvatar'
import { Wrapper, Image, ShimmerAnim, Svg } from './styles'

const Avatar = props => {
    const { src, alt, type, gender, isSvg, freeSize, isNameOH } = props

    const isTeacher = type?.toLowerCase() === userTypes.TEACHER

    const imgSrc =
        isTeacher && !src
            ? teacher[gender?.toLowerCase() || genderTypes.female]
            : src

    const svg = (
        <Svg
            viewBox="90 50 320 410"
            xmlns={xmlnsUrl}
            dangerouslySetInnerHTML={{ __html: isNameOH ? ohAvatar : imgSrc }}
            freeSize={freeSize}
        />
    )

    const img = <Image src={imgSrc} alt={alt} freeSize={freeSize} />

    let avatar = isNameOH || isSvg ? svg : img

    avatar = type ? avatar : null

    if (freeSize) return avatar

    return <Wrapper>{avatar ? avatar : <ShimmerAnim />}</Wrapper>
}

Avatar.propTypes = {
    src: PropTypes.string,
    isSvg: PropTypes.bool,
    gender: PropTypes.string,
    type: PropTypes.string,
    isNameOH: PropTypes.bool,
}

Avatar.defaultProps = {
    src: '',
    isSvg: true,
    gender: 'female',
    isNameOH: false,
}

export default Avatar
