import styled from 'styled-components'

export const Container = styled.div`
    height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 80px;
    & > span:last-of-type {
        width: 80%;
        margin: 25px;
        text-align: center;
    }
    @media screen and (orientation: landscape) {
        margin: 0 auto;
        height: 100vh;
        justify-content: center;
        & > span:last-of-type {
            width: 40%;
        }
    }
`

export const Button = styled.div`
    color: #de6645;
    text-decoration: underline;
    cursor: pointer;
`

export const Image = styled.img`
    margin-bottom: 15px;
`
