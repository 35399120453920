import colors from './colors'
import { themes } from './constants'

export default {
    id: `T_001`,
    name: themes.DARK,
    styles: {
        body: colors.brand.dark,
        color: colors.white,
        button: {
            primary: {
                enabled: {
                    color: colors.white,
                    background: colors.brand.orange,
                    border: `none`,
                },
                disabled: {
                    color: colors.tertiaryWhite,
                    background: `#FFFFFF1f`,
                    border: `none`,
                },
            },
            secondary: {
                enabled: {
                    color: colors.white,
                    background: colors.transparent,
                    border: `1px solid ${colors.tertiaryWhite}`,
                },
                disabled: {},
            },
        },
        filter: {
            default: {
                color: colors.white,
                background: colors.transparent,
                border: `1px solid ${colors.tertiaryWhite}`,
            },
            selected: {
                color: colors.white,
                background: `linear-gradient(0deg, #FFE1D94D, #FFE1D94D), ${colors.brand.dark}`,
                border: `1px solid ${colors.tertiaryWhite}`,
            },
        },
        loader: {
            stroke: colors.brand.orange,
        },
        scrollToTop: {
            background: `linear-gradient(0deg, #FFFFFF33, #FFFFFF33), ${colors.brand.dark}`,
            arrow: colors.white,
        },
        skeleton: {
            background: `#4B4450`,
        },
        callScheduler: {
            background: `#58515E`,
            color: colors.white,
            staticCard: {
                background: `#58515E`,
                color: `#FFFFFF`,
            },
        },
        submissions: {
            background: `#000000`,
        },
        linkers: {
            background: `#453C4D`,
        },
        modals: {
            background: '#352D3B',
            color: colors.white,
            overlay: {
                background: '#1A141E',
            },
        },
        share: {
            background: '#453C4C',
            color: colors.white,
        },
        instructions: {
            background: '#453C4D',
            color: colors.white,
        },
        checkout: {
            date: {
                hightlight: colors.brand.orange,
            },
            input: {
                background: '#3f3845',
            },
        },
    },
}
