import React, { useEffect } from 'react'
import { withOrientationChange } from 'react-device-detect'
import ReactGA from 'react-ga'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import Loader from 'components/common/Loader'
import { NEWSLETTER } from 'constants/pages'
import { isPathname } from 'helpers/miscellaneous'
import { initiateThirdPartyScripts } from 'helpers/scripts'
import { submissionPageHelper } from 'helpers/storage'
import useGetAssignments from 'hooks/useGetAssignments'
import useSiteLoading from 'hooks/useSiteLoading'
import Routes from 'routes'
import { _authenticateUserLogin } from 'store/dispatchers/user'
import * as assignmentSelectors from 'store/selectors/assignments'

import CallSchedular from './CallSchedular'
import Error from './common/Error'
import Footer from './Footer'
import ScrollToTop from './ScrollToTop'
import SiteSkeleton from './SiteSkeleton'
import { Container, MobileView, LoaderWrapper } from './styles'

const errorProps = {
    message:
        'We currently do not support landscape mode. Please switch to portrait mode.',
}

const App = props => {
    const { hasNoData, assignments, fetchMoreData } = useGetAssignments()
    const isFetchingAssignments = useSelector(assignmentSelectors._isLoading)
    const isProfilePageOpen = isPathname('profile')
    const isNewsletterPage = isPathname(NEWSLETTER)
    const pageNotFound = isPathname('not-found')
    const hasSiteLoaded = useSiteLoading()

    const isSubmissionPage = submissionPageHelper.get()

    const isModalOpen = !!document?.getElementById('custom-modal')
    const style = { overflow: isModalOpen ? 'hidden' : 'hidden auto' }
    const userID = useSelector(state => state.user?.userInfo?.id)

    const scrollProps = {
        // disable scroller loader if isFetchingAssignments is true
        loader:
            isSubmissionPage ||
            isNewsletterPage ||
            isFetchingAssignments ? null : (
                <LoaderWrapper>
                    <Loader size="lg" />,
                </LoaderWrapper>
            ),
        pageStart: 0,
        throttle: 100,
        threshold: 300,
    }

    useEffect(() => {
        _authenticateUserLogin()
        initiateThirdPartyScripts()
    }, [])

    useEffect(() => {
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID)
        let user = userID || 'Unknown'
        let path = window.location.pathname
        ReactGA.pageview(`${path} viewed by ${user}`)
    }, [userID])

    if (!hasSiteLoaded) return <SiteSkeleton />

    if (props.isLandscape) return <Error {...errorProps} />

    // remove schedule a call section when page is loading
    const showNoMoreContent = !isFetchingAssignments &&
        hasNoData &&
        !isProfilePageOpen && <CallSchedular type="noMoreContent" />

    // remove footer when page is loading
    const shouldBlockFeature =
        isFetchingAssignments || pageNotFound
            ? null
            : !isProfilePageOpen && !isSubmissionPage && !isNewsletterPage
    return (
        <BrowserRouter>
            <MobileView>
                <Container>
                    <InfiniteScroll
                        dataLength={assignments && assignments?.results?.length}
                        next={
                            !isSubmissionPage &&
                            !isNewsletterPage &&
                            fetchMoreData
                        }
                        hasMore={!hasNoData}
                        scrollThreshold={0.4}
                        style={style}
                        {...scrollProps}
                    >
                        <Routes />
                        {showNoMoreContent}
                    </InfiniteScroll>
                    {shouldBlockFeature && (
                        <>
                            <ScrollToTop />
                            <Footer />
                        </>
                    )}
                </Container>
            </MobileView>
        </BrowserRouter>
    )
}

export default withOrientationChange(App)
