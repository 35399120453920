import React from 'react'

import Avatar from 'components/common/Avatar'

import {
    Wrapper,
    SubWrapper,
    PreviewArea,
    TextPrimary,
    TextSecondary,
    AvatarWrapper,
} from './styles'

const renderSkeletons = count =>
    new Array(count).fill(null).map((_, index) => (
        <Wrapper key={index}>
            <PreviewArea count={index} />
            <TextPrimary />
            <SubWrapper>
                <AvatarWrapper>
                    <Avatar />
                </AvatarWrapper>
                <TextSecondary />
            </SubWrapper>
        </Wrapper>
    ))

const Skeleton = ({ count }) => renderSkeletons(count)

Skeleton.defaultProps = {
    count: 5,
}

export default Skeleton
