import { ohr } from 'utils/axios'
import logger from 'utils/logger'
import resourcesPath from 'utils/resourcesPath'

import { SUBJECTS, CLUBS } from '../constants/filters'

const { FILTERS, RESPONSIVE_FILTERS } = resourcesPath

export const fetchFilters = async () => {
    try {
        const { data } = await ohr.get(FILTERS)
        return data
    } catch (error) {
        logger.error(error.message)
    }
}

export const getResponsiveFilters = async query => {
    try {
        const { data } = await ohr.get(`${RESPONSIVE_FILTERS}${query}`)
        const res = Object.keys(data).reduce((obj, key) => {
            if ([SUBJECTS, CLUBS].includes(key)) {
                const clubs = data?.clubs || []
                // check for duplicate
                const isExist = clubs.includes(...data[key])
                obj[SUBJECTS] = isExist ? [...clubs] : [...clubs, ...data[key]]
            } else {
                obj[key] = data[key]
            }
            return obj
        }, {})

        return res
    } catch (error) {
        logger.error(error.message)
    }
}
