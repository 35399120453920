import { isObjectEmpty } from './object'

export const appendScriptToDOM = (script, dataId) => {
    const element = document.head.querySelector(`[data-id="${dataId}"]`)

    const isElementExist = !isObjectEmpty(element)

    if (isElementExist) {
        // remove element
        document.head.removeChild(element)
    }

    // append element
    const scriptElement = document.createElement('script')
    scriptElement.setAttribute('data-id', dataId)
    scriptElement.innerHTML = script

    document.head.appendChild(scriptElement)
}

export const initiateHotjarScript = id => {
    if (id) {
        const dataId = 'hotjar-script'

        const script = `
            ; (function (h, o, t, j, a, r) {
                h.hj =
                    h.hj ||
                    function () {
                        ; (h.hj.q = h.hj.q || []).push(arguments)
                    }
                h._hjSettings = { hjid: ${id}, hjsv: 6 }
                a = o.getElementsByTagName('head')[0]
                r = o.createElement('script')
                r.async = 1
                r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
                a.appendChild(r)
            })(
                window,
                document,
                'https://static.hotjar.com/c/hotjar-',
                '.js?sv='
            )`

        appendScriptToDOM(script, dataId)
    }
}

export const initiateHeapScript = id => {
    if (id) {
        const dataId = 'heap-script'

        const script = `
            ; (window.heap = window.heap || []),
                (heap.load = function (e, t) {
                    ; (window.heap.appid = e), (window.heap.config = t = t || {})
                    var r = document.createElement('script')
                        ; (r.type = 'text/javascript'),
                            (r.async = !0),
                            (r.src =
                                'https://cdn.heapanalytics.com/js/heap-' +
                                e +
                                '.js')
                    var a = document.getElementsByTagName('script')[0]
                    a.parentNode.insertBefore(r, a)
                    for (
                        var n = function (e) {
                            return function () {
                                heap.push(
                                    [e].concat(
                                        Array.prototype.slice.call(
                                            arguments,
                                            0
                                        )
                                    )
                                )
                            }
                        },
                        p = [
                            'addEventProperties',
                            'addUserProperties',
                            'clearEventProperties',
                            'identify',
                            'resetIdentity',
                            'removeEventProperty',
                            'setEventProperties',
                            'track',
                            'unsetEventProperty',
                        ],
                        o = 0;
                        o < p.length;
                        o++
                    )
                        heap[p[o]] = n(p[o])
                })
            heap.load('${id}')`

        appendScriptToDOM(script, dataId)
    }
}

export const initiateThirdPartyScripts = () => {
    const { REACT_APP_HOTJAR_ID, REACT_APP_HEAP_APP_ID } = process.env

    initiateHotjarScript(REACT_APP_HOTJAR_ID)
    initiateHeapScript(REACT_APP_HEAP_APP_ID)
}
