import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { NEWSLETTER } from 'constants/pages'
import { isPathname } from 'helpers/miscellaneous'
import { schoolPageHelper } from 'helpers/storage'
import { _isLoading } from 'store/dispatchers/assignments'
import * as assignmentSelectors from 'store/selectors/assignments'
import * as filterSelectors from 'store/selectors/filters'
import {
    initializeAssignments,
    fetchMoreAssignments,
    applyAssignmentFilters,
} from 'store/thunks/assignments'
import { initializeFilters } from 'store/thunks/filters'

import { createFiltersQueryString } from './helpers'

const useGetAssignments = () => {
    const assignments = useSelector(assignmentSelectors._data)
    const filters = useSelector(filterSelectors._selected)
    const searchText = useSelector(filterSelectors._search)
    const filtersData = useSelector(filterSelectors._data)
    const clubs = useSelector(filterSelectors._clubs)
    const [hasNoData, setHasNoData] = useState(false)
    const isSchoolPage = schoolPageHelper.get()
    const isNewsletterPage = isPathname(NEWSLETTER)

    useEffect(() => {
        const hasSelectedFilters =
            filters && Object.keys(filters)?.some(key => !!filters[key].length)

        const isProfilePage = isPathname('profile')

        ;(async () => {
            _isLoading(true)
            if (hasSelectedFilters) {
                console.log(
                    'applying assignment filters from get assignments',
                    filters,
                    clubs
                )
                let query = createFiltersQueryString(filters, clubs)
                if (searchText) {
                    query += `&search=${searchText}`
                }
                await applyAssignmentFilters(query)
            } else {
                // prevent calling initialize assignments on profile page
                if (!isSchoolPage && !isProfilePage && !isNewsletterPage) {
                    if (searchText) {
                        let query = `?search=${searchText}`
                        await applyAssignmentFilters(query)
                    } else {
                        initializeAssignments()
                    }
                }
            }
        })()
    }, [filters, searchText])

    useEffect(() => {
        if (isNewsletterPage) return
        !filtersData && initializeFilters()
    }, [filtersData, isNewsletterPage])

    useEffect(() => {
        assignments?.next && setHasNoData(false)
    }, [assignments])

    const fetchMoreData = async () => {
        if (!assignments || (assignments && !assignments?.next)) {
            setHasNoData(true)
            return
        }

        await fetchMoreAssignments(assignments?.next)
    }

    return { hasNoData, assignments, fetchMoreData }
}
export default useGetAssignments
