import styled, { css } from 'styled-components'

import colors from 'themes/colors'

export const StaticCardContainer = styled.div`
    background: ${props =>
        props.theme.styles.callScheduler.staticCard.background};
    display: flex;
    border-radius: 8px;
    color: #fff;
    padding: 15px;
    width: 90%;
`

export const StaticRightContents = styled.div`
    flex: 3;
    h2 {
        font-size: 16px;
        line-height: 26px;
        margin: 5px 0;
    }
    button {
        margin: 15px 0;
        outline: none;
    }
    span {
        color: ${props => props.theme.styles.callScheduler.staticCard.color};
    }
`

export const StaticLeftContents = styled.div`
    flex: 1;
    display: flex;
    align-items: flex-end;
`
export const Header = styled.div`
    margin: 15px 0;
    span {
        float: left;
        width: 100%;
    }
    ${props =>
        props.dateList &&
        css`
            button:first-child:after {
                background: ${props =>
                    props.theme.styles.checkout.date.hightlight};
                border-radius: 50px;
                content: '';
                display: block;
                line-height: 50px;
                height: 7px;
                margin: auto;
                width: 7px;
                font-size: 0;
                position: absolute;
                bottom: -15px;
            }
            button:first-child {
                position: relative;
                margin-left: 0;
            }
            button {
                padding: 35px 10px !important;
                span {
                    span {
                        margin: 5px 0;
                    }
                    span:first-child {
                        font-family: 'Avenir';
                        text-transform: capitalize;
                    }
                    span:last-child {
                        font-size: 20px;
                    }
                }
            }
        `}
    ${props =>
        props.slotsList &&
        css`
            span span:last-child {
                margin-top: 5px;
                font-family: 'Avenir';
            }
            span span:first-child {
                font-size: 14px;
                font-family: 'Avenir';
            }
            button {
                height: 60px;
            }
        `}
`

export const Top = styled.div`
    padding: 15px 0 0 5vw;
    margin-top: 3vh;
    background-color: #352d3b;
    button {
        background-color: transparent;
        border: transparent;
        outline: none;
        cursor: pointer;
    }
    img {
        width: 15px;
    }
    @media (min-width: 992px) {
        margin-top: 0;
        padding: 15px 0 0 0;
    }
`

export const StepTwoForm = styled.div`
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
`
export const InputContainer = styled.div`
    margin: 15px 0;
    display: flex;
    flex-direction: column;
    min-height: 100px;
    input[type='text'],
    input[type='tel'] {
        padding: 15px;
        border-radius: 8px;
        background: ${props => props.theme.styles.checkout.input.background};
        border: transparent;
        margin-top: 10px;
        color: #fff;
        outline: none;
    }
`

export const ButtonContainer = styled.div`
    float: left;
    width: 100%;
    margin-top: 15px;
`

export const ButtonList = styled.div`
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    height: max-content;
    display: flex;
    margin-top: 5px;
    button {
        width: 60px;
        margin: 5px 5px 15px;
        padding: 5px 10px;
        background: ${props => props.theme.styles.checkout.input.background};
    }
    .activeButton,
    .activeDate {
        border: 1px solid #de6645;
    }
`

export const ButtonListSlot = styled.div`
    width: 100%;
    overflow-y: auto;
    display: flex;
    button {
        float: left;
        width: 100px;
        margin: 5px;
        padding: 0;
        background: ${props => props.theme.styles.checkout.input.background};
    }
    .activeSlot {
        border: 1px solid #de6645;
    }
`

export const CheckboxContainer = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 15px;
`

export const ThankyouContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 80%;
    margin: auto;
`

export const Container = styled.div`
    width: 70%;
    @media (min-width: 992px) {
        width: 50%;
        margin-bottom: 30px;
    }
`

export const Conatiner = styled.div`
    height: 100%;
`
export const Form = styled.div`
    display: flex;
    flex-direction: column;
`

export const ModalContents = styled.div`
    width: 100vw;
    padding: 5vw;
    height: 90%;
    justify-content: space-between;
    box-sizing: border-box;
    flex-direction: column;
    margin-bottom: 5vh;
    display: flex;
    @media (min-width: 992px) {
        margin-top: 0;
        width: 100%;
        padding: 0;
        height: 95%;
    }
`

export const LearnWithMeButton = styled.div`
    width: 100%;
    height: 48px;
    background: ${colors.brand.orange};
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: ${colors.white};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    text-transform: lowercase;
`
