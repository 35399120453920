import {
    isIOS,
    isMacOs,
    isMobile,
    isMobileOnly,
    isTablet,
} from 'react-device-detect'

const isIPad =
    (isTablet && (isIOS || isMacOs)) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0)

const isDesktop = !isMobile && !isTablet

const isDesktopOrIpad = isDesktop || (isTablet && isIPad)

const isMobileOrIPad = isMobileOnly || isIPad

export { isIPad, isDesktop, isMobileOnly, isMobileOrIPad, isDesktopOrIpad }
