import styled from 'styled-components'

import { cssFromStylesObject } from 'helpers/css'

import { btnSizes } from './constants'

export const Wrapper = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    box-sizing: border-box;
    border-radius: 8px;
    span {
        text-transform: ${props => (props.normalcase ? 'none' : 'lowercase')};
    }
    ${props => {
        const { disabled, isLoading, type, size, theme } = props

        let themeStyles = theme.styles.button[type]
        themeStyles = disabled ? themeStyles.disabled : themeStyles.enabled
        const { width, height, unit } = btnSizes[size]
        const filter = disabled ? `grayscale(100%)` : `none`
        const cursor = disabled || isLoading ? `not-allowed` : `pointer`

        return `
            filter: ${filter};
            cursor: ${cursor};
            width:${width}${unit};
            height:${height}${unit};
            ${cssFromStylesObject(themeStyles)}
        `
    }}

    &:focus {
        outline: none;
    }
`
