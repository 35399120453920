import { addDays, startOfDay, format } from 'date-fns'

// fetches sevens dates and day names
export const getNext7Days = () => {
    const startDate = startOfDay(new Date())
    const endDate = addDays(startDate, 6)
    const date = []
    let day = startDate
    let id = 0
    while (day <= endDate) {
        date.push({
            id,
            date: day,
            dateString: format(day, 'd'),
            name: format(day, 'E'),
        })
        day = addDays(day, 1)
        id += 1
    }
    return date
}

export const convertTowDIgit = n => {
    return `0${n}`.slice(-2)
}

export const getYmdFormat = date => {
    return `${date.getFullYear()}-${convertTowDIgit(
        date.getMonth() + 1
    )}-${convertTowDIgit(date.getDate())}`
}

export const staticCardPos = [...Array(10)].map((_, i) => 5 + i * 10)
