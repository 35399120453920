import styled from 'styled-components'

import { loaderSizes } from './constant'
import { rotate, rotatePath } from './keyframes'

export const Spinner = styled.svg`
    display: inherit;
    margin: auto;
    animation: ${rotate} 2s linear infinite;
    ${({ size }) => {
        const { width, height, unit } = loaderSizes[size]
        return `
            width: ${width}${unit};
            height: ${height}${unit};
        `
    }}

    circle {
        stroke-linecap: round;
        animation: ${rotatePath} 1.5s ease-in-out infinite;
        ${props => `stroke: ${props.theme.styles.loader.stroke}`};
    }
`
