const ASSIGNMENTS_BASE_URL = '/oh_portfolios/assignment'

const resourcesPath = {
    // leaderboard
    LEADERBAORD: `${ASSIGNMENTS_BASE_URL}/leaderboard`,

    // newsletter
    NEWSLETTER_URL: `/oh_portfolios/newsletter`,
    NEWSLETTER_SUBSCRIBER: `/oh_portfolios/newsletter/subscriber`,

    // subscriber
    SUBSCRIBER: `/oh_portfolios/newsletter/subscriber`,

    // filters
    FILTERS: `${ASSIGNMENTS_BASE_URL}/filters`,
    RESPONSIVE_FILTERS: `${ASSIGNMENTS_BASE_URL}/filters/optimize`,

    // assignments
    ASSIGNMENTS: `${ASSIGNMENTS_BASE_URL}`,
    ASSIGNMENT_VIEWS: `${ASSIGNMENTS_BASE_URL}/increase/views`,

    //instructions
    INSTRUCTIONS: '/oh_portfolios/instruction',

    //comments
    COMMENTS: '/oh_portfolios/comment',

    //like
    LIKE: '/oh_portfolios/like',

    // profile
    GET_PROFILE: `${ASSIGNMENTS_BASE_URL}/teacher/details`,

    // upload image
    UPLOAD_IMAGE:
        'https://api.cloudinary.com/v1_1/openhouse-study/image/upload',

    // boards
    BOARDS: 'commons/board',

    // school
    GET_SCHOOL: 'commons/school',

    // users
    USER: 'base/user/details',
    UPDATE_USER_INFO: 'oh_users',

    // auth
    VERIFY_OTP: 'base/user/create/signup/',
    CREATE_USER: 'base/user/verify/signup/',
    SEND_OTP: 'auth/send_otp/',
    LOGIN: 'auth/verify_otp/',
    REFRESH_TOKEN: 'auth/refresh_token',
}

export default resourcesPath
