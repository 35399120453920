import React from 'react'

import { Wrapper, ButtonShimmer } from './styles'

const buttonShimmer = new Array(5)
    .fill(null)
    .map((_, i) => <ButtonShimmer key={i} />)

const ShimmerFilters = () => <Wrapper>{buttonShimmer}</Wrapper>

export default ShimmerFilters
