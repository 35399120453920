import React from 'react'

import CustomModal from 'components/common/CustomModal'

import StepOne from '../StepOne'
import StepThree from '../StepThree'
import StepTwo from '../StepTwo'

const ScheduleModal = ({
    modalProps,
    stepOneProps,
    stepTwoProps,
    stepThreeProps,
    screen,
}) => {
    return (
        <CustomModal {...modalProps}>
            {screen === 1 ? (
                <StepOne {...stepOneProps} />
            ) : screen === 2 ? (
                <StepTwo {...stepTwoProps} />
            ) : (
                <StepThree {...stepThreeProps} />
            )}
        </CustomModal>
    )
}

export default ScheduleModal
