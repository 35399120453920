import {
    initializeAssignments,
    updateAssignments,
    applyFilters,
    isFetchingAssignments,
    getNewsletterSuccesss,
} from 'store/actions/assignments'

import store from '../index'

const { dispatch } = store

export const _initializeAssignments = payload =>
    dispatch(initializeAssignments(payload))

export const _upateAssignmentsList = payload =>
    dispatch(updateAssignments(payload))

export const _applyFilters = payload => dispatch(applyFilters(payload))

export const _getNewslettersDispatch = payload =>
    dispatch(getNewsletterSuccesss(payload))

export const _isLoading = payload => dispatch(isFetchingAssignments(payload))
