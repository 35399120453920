import styled, { keyframes } from 'styled-components'

const fadeIn = keyframes`
    0% { opacity: 0% }
    100% { opacity: 100% }
`

export const Wrapper = styled.div`
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 7;
    align-items: flex-end;
    z-index: 100;
    animation: 300ms linear ${fadeIn} forwards;
    @media (min-width: 992px) {
        justify-content: center;
    }
`

export const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: flex-end;
    animation: 300ms linear ${fadeIn} forwards;
    z-index: 99;
`

export const Container = styled.div`
    background: #352d3b;
    z-index: 999;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
        width: 0 !important;
    }
    @media (min-width: 992px) {
        height: 70%;
        width: 700px !important;
        background: #352d3b;
        padding: 10px;
        box-sizing: border-box;
    }
`
