import { envTypes } from 'constants/environment'
import config from 'utils/config'

export const isDevelopment = config.environment === envTypes.DEVELOPMENT

export const uuid = () => {
    let d = new Date().getTime() //Timestamp
    let d2 = (performance && performance.now && performance.now() * 1000) || 0 //Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        let r = Math.random() * 16 //random number between 0 and 16
        if (d > 0) {
            //Use timestamp until depleted
            r = (d + r) % 16 | 0
            d = Math.floor(d / 16)
        } else {
            //Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0
            d2 = Math.floor(d2 / 16)
        }
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
    })
}

export const sleep = (ms = 0) => new Promise(resolve => setTimeout(resolve, ms))

export const getWhatsappCopy = (subjectName, teacherName) =>
    `Hey, look how I am learning ${subjectName ||
        ''} with ${teacherName} at Openhouse. Come check it out!`

export const trackActivityOnHeap = name => window?.heap?.track(name)

export const capitalized = str => str.charAt(0).toUpperCase() + str.slice(1)

export const isPathname = path => window?.location?.pathname.includes(path)
