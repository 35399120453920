import {
    CHECKOUT_FLOW,
    IS_SUBMISSION_PAGE,
    SCHOOL_PAGE,
} from 'constants/browserStorage'

const submissionPageHelper = {
    set: val =>
        window?.sessionStorage.setItem(IS_SUBMISSION_PAGE, JSON.stringify(val)),
    get: () => {
        const value = window?.sessionStorage.getItem(IS_SUBMISSION_PAGE)
        return JSON.parse(value)
    },
    del: () => window?.sessionStorage.removeItem(IS_SUBMISSION_PAGE),
}

const checkoutFlowHelper = {
    set: val =>
        window?.sessionStorage.setItem(CHECKOUT_FLOW, JSON.stringify(val)),
    get: () => {
        const value = window?.sessionStorage.getItem(CHECKOUT_FLOW)
        return JSON.parse(value)
    },
    del: () => window?.sessionStorage.removeItem(CHECKOUT_FLOW),
}

const schoolPageHelper = {
    set: val =>
        window?.sessionStorage.setItem(SCHOOL_PAGE, JSON.stringify(val)),
    get: () => {
        const value = window?.sessionStorage.getItem(SCHOOL_PAGE)
        return JSON.parse(value)
    },
    del: () => window?.sessionStorage.removeItem(SCHOOL_PAGE),
}

export { submissionPageHelper, checkoutFlowHelper, schoolPageHelper }
