import { SET_INFO_HIDDEN } from '../types/profilePage'

const initialState = {
    isProfilePageOpen: false,
    isInfoHidden: false,
}

const profilePageReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_INFO_HIDDEN:
            return {
                ...state,
                isInfoHidden: action.isHidden,
            }

        default:
            return state
    }
}

export default profilePageReducer
