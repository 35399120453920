const defaultKeys = {
    UUID: 'uuid',
    SUBJECTS: 'subject',
    CLASSES: 'standard',
    TEACHER: 'teacher',
    USER_ID: 'user_id',
    SCHOOL_UUID: 'school_uuid',
    CLUBS: 'club_uuid',
}

const assignmentFilterKeys = {
    ...defaultKeys,
    CATEGORIES: 'categories',
}

const instructionFilterKeys = {
    ...defaultKeys,
}

const filterKeys = {
    CATEGORIES: 'categories',
    CLASSES: 'classes',
    CLUBS: 'clubs',
    SUBJECTS: 'subjects',
}

export { assignmentFilterKeys, instructionFilterKeys, filterKeys }
