import styled from 'styled-components'

import { shimmer } from 'animations/shimmer'

export const Container = styled.div`
    position: relative;
    width: 100%;
    height: 100vh;
    z-index: 30;
    border-left: 1px solid #ffffff2e;
    border-right: 1px solid #ffffff2e;
    @media (min-width: 992px) {
        width: 700px;
    }
`

export const SkeletonWrap = styled.div`
    > div {
        background: #261e2c;
        border-left: 1px solid #ffffff2e;
        border-right: 1px solid #ffffff2e;
    }
`

export const HeaderShimmerAnim = styled.div`
    width: inherit;
    height: 75%;
    ${shimmer};
`
