import styled from 'styled-components'

export const Container = styled.div`
    background-color: #3c3541;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 25px 0 22vh 0;
    p {
        color: #f2f2f2;
        opacity: 0.5;
        margin: 0;
    }
    a {
        text-decoration: none;
        font-size: 14px;
    }
`

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px 15px 0;
    img {
        margin: 0;
        width: 30px;
    }
    a {
        margin: 15px;
    }
`
