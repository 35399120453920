import axios from 'axios'

import { identity } from 'helpers/identity'
import { isDevelopment } from 'helpers/miscellaneous'

import config from './config'

const LEVELS = {
    ERROR: 'error',
    WARN: 'warn',
    INFO: 'info',
}

const LOGGER_URI = ' https://logger.openhouse.study'

const { environment, app } = config

const product = `${app.name}`

const letsLogIt = (message, type) => {
    const payload = {
        message: `${[type]} ${message}`,
        streamName: identity.get(),
        environment,
        product,
    }
    if (isDevelopment) return console[type](message)
    try {
        axios.post(LOGGER_URI, payload)
    } catch (error) {
        console.error('log to cloudwatch failed')
    }
}

const logger = {
    error: message => letsLogIt(message, LEVELS.ERROR),
    warn: message => letsLogIt(message, LEVELS.WARN),
    info: message => letsLogIt(message, LEVELS.INFO),
}

export default logger
