import { uuid } from './miscellaneous'

export const OH_UNIQUE_IDENTITY = 'OH_UNIQUE_IDENTITY'

export const identity = {
    set: () => {
        const id = uuid()
        localStorage.setItem(OH_UNIQUE_IDENTITY, id)
        return id
    },
    get: () => localStorage.getItem(OH_UNIQUE_IDENTITY),
    del: () => localStorage.removeItem(OH_UNIQUE_IDENTITY),
}
