import { ErrorBoundary } from '@sentry/react'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'

import App from 'components'
import MetaData from 'components/MetaData'
import ErrorPage from 'pages/Error'
import store from 'store'
import { dark, GlobalStyles } from 'themes'
import { initialize as initSentry } from 'utils/sentry'
import 'react-responsive-modal/styles.css'

initSentry()

const metaDataProps = {
    title: 'Openhouse after-school learning | Learn by actually doing',
    description:
        "Openhouse offers a holistic after-school learning experience to students. We are redefining ed-tech with active and engaging online classes where we make invisible learning visible. When you're actually learning by doing, there's nothing like it.",
}

const Portfolio = () => (
    <ErrorBoundary fallback={<ErrorPage text="Something went wrong!" />}>
        <MetaData {...metaDataProps} />
        <ThemeProvider theme={dark}>
            <GlobalStyles />
            <Provider store={store}>
                <App />
            </Provider>
        </ThemeProvider>
    </ErrorBoundary>
)

ReactDOM.render(<Portfolio />, document.getElementById('root'))
