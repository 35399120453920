export const loaderSizeKeys = {
    SMALL: 'sm',
    MEDIUM: 'md',
    LARGE: 'lg',
    FREESIZE: 'fs',
}

export const loaderSizes = {
    [loaderSizeKeys.LARGE]: {
        width: 44,
        height: 44,
        unit: 'px',
    },
    [loaderSizeKeys.MEDIUM]: {
        width: 36,
        height: 36,
        unit: 'px',
    },
    [loaderSizeKeys.SMALL]: {
        width: 26,
        height: 26,
        unit: 'px',
    },
    [loaderSizeKeys.FREESIZE]: {
        width: 100,
        height: 100,
        unit: '%',
    },
}
