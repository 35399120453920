import styled from 'styled-components'

import { shimmer } from 'animations/shimmer'

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    padding: 10px 0 0 15px;
    background: #261e2c;
`

export const ButtonShimmer = styled.div`
    background: #4b4450;
    height: 40px;
    margin: 15px 20px 30px 0;
    border-radius: 8px;
    @media (max-width: 992px) {
        &:nth-child(1) {
            width: 250px;
        }
        &:nth-child(2) {
            width: 150px;
        }
        &:nth-child(3) {
            width: 50px;
            margin: 15px 0 0 0;
            border-radius: 8px 0 0 8px;
        }
        &:nth-child(4),
        &:nth-child(5) {
            display: none;
        }
    }
    @media (min-width: 992px) {
        width: 250px;
    }
    ${shimmer}
`
