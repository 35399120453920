import PropTypes from 'prop-types'
import React from 'react'

import { Container, Image, Button } from './styles'

import Text from '../Text'

const imageProps = {
    src: '/images/misc/cat.svg',
    alt: 'Warning',
}

const Error = ({ message, buttonText, onClick, heading }) => (
    <Container>
        <Image {...imageProps} />
        <Text level="4" color="#ffffff8f">
            {heading || `Oops!`}
        </Text>
        <Text level="3" bold={false} color="#ffffff8f">
            {message}
        </Text>
        {buttonText && <Button onClick={onClick}>{buttonText}</Button>}
    </Container>
)

Error.defaultProps = {
    buttonText: '',
    message: 'We did not find anything for your selection.',
    onClick: () => {},
}

Error.propTypes = {
    buttonText: PropTypes.string,
    message: PropTypes.string,
    onClick: PropTypes.func,
}

export default Error
