import { keyframes } from 'styled-components'

const rotate = keyframes`
     100% {
         transform: rotate(360deg);
     }
`

const rotatePath = keyframes`
    0% {
         stroke-dasharray: 1, 150;
         stroke-dashoffset: 0;
     }
     50% {
         stroke-dasharray: 90, 150;
         stroke-dashoffset: -35;
     }
     100% {
         stroke-dasharray: 90, 150;
         stroke-dashoffset: -124;
     }
`

export { rotate, rotatePath }
