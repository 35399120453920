import styled from 'styled-components'

export const Svg = styled.svg`
    width: 16px;
    height: 16px;
    fill: none;
    @media (max-width: 992px) {
        width: 12px;
        height: 12px;
    }
`
export const Path = styled.path`
    fill: ${props => props.theme.styles.scrollToTop.arrow};
    stroke-width: 2;
    stroke-linecap: round;
`
