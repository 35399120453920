import React from 'react'

import Button, { btnSizeKeys, btnTypes } from 'components/common/Button'
import Text from 'components/common/Text'

import { getNext7Days } from '../../helpers'
import * as SchedularStyle from '../../styles'

const days = getNext7Days()

const StepTwo = ({
    goToStepOne,
    dateHandler,
    applyActiveDate,
    slotsDetails,
    slotHandler,
    applyActiveSlot,
    goToStepThree,
    dateSelected,
    slotSelected,
    isButtonLoading,
}) => {
    return (
        <>
            <SchedularStyle.Top>
                <button onClick={goToStepOne}>
                    <img
                        src="/images/checkout/back_arrow.svg"
                        alt="back arrow"
                    />
                </button>
            </SchedularStyle.Top>
            <SchedularStyle.ModalContents>
                <SchedularStyle.StepTwoForm>
                    <SchedularStyle.Header>
                        <Text color="#DE6645" level="1">
                            Step 2 of 2
                        </Text>
                        <Text level="5">When can we call you?</Text>
                        <Text level="3" bold={false}>
                            So that we call you at a good time.
                        </Text>
                    </SchedularStyle.Header>
                    <SchedularStyle.Header dateList>
                        <Text level="2" bold={false}>
                            Select date
                        </Text>
                        <SchedularStyle.ButtonList>
                            {days.map((day, index) => {
                                return (
                                    <Button
                                        key={index}
                                        size={btnSizeKeys.SMALL}
                                        type={btnTypes.SECONDARY}
                                        onClick={() =>
                                            dateHandler(
                                                index,
                                                `${day.dateString} ${day.name}`,
                                                day.date
                                            )
                                        }
                                        className={
                                            applyActiveDate === index
                                                ? 'activeDate'
                                                : ''
                                        }
                                    >
                                        <Text bold={false} level="2">
                                            {day.name}
                                        </Text>
                                        <Text level="1" bold={false}>
                                            {day.dateString}
                                        </Text>
                                    </Button>
                                )
                            })}
                        </SchedularStyle.ButtonList>
                    </SchedularStyle.Header>
                    <SchedularStyle.Header slotsList>
                        <Text level="2" bold={false}>
                            Select time slot
                        </Text>
                        <SchedularStyle.ButtonListSlot>
                            {slotsDetails.map((slot, index) => {
                                return (
                                    <Button
                                        key={index}
                                        size={btnSizeKeys.SMALL}
                                        type={btnTypes.SECONDARY}
                                        onClick={() =>
                                            slotHandler(
                                                index,
                                                `${slot.period} ${slot.timeWindow}`
                                            )
                                        }
                                        className={
                                            applyActiveSlot === index
                                                ? 'activeSlot'
                                                : ''
                                        }
                                    >
                                        <Text bold={false}>{slot.period}</Text>
                                        <Text
                                            bold={false}
                                            color="rgba(255, 255, 255, 0.56)"
                                        >
                                            {slot.timeWindow}
                                        </Text>
                                    </Button>
                                )
                            })}
                        </SchedularStyle.ButtonListSlot>
                    </SchedularStyle.Header>
                </SchedularStyle.StepTwoForm>
                <SchedularStyle.ButtonContainer>
                    <Button
                        onClick={async () => await goToStepThree()}
                        size={btnSizeKeys.FREESIZE}
                        disabled={
                            !dateSelected || !slotSelected || isButtonLoading
                        }
                        isLoading={isButtonLoading}
                    >
                        Confirm
                    </Button>
                </SchedularStyle.ButtonContainer>
            </SchedularStyle.ModalContents>
        </>
    )
}

export default StepTwo
