import styled from 'styled-components'

const defaultStyles = `
    float: left;
    width: 100%;
`

export const PhoneLink = styled.div`
    button {
        position: absolute;
        width: 90%;
        height: 40px;
        bottom: 35px;
    }
    a {
        margin-top: 25px;
        ${defaultStyles}
    }
    @media (min-width: 992px) {
        button {
            width: 70%;
        }
    }
`

export const Container = styled.div`
    ${defaultStyles}
    margin-top: 15px;
`

export const Space = styled.div`
    margin-top: 45px;
    ${defaultStyles}
`
