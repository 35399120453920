export const btnTypes = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
}

export const btnSizeKeys = {
    SMALL: 'sm',
    MEDIUM: 'md',
    LARGE: 'lg',
    FREESIZE: 'fs',
}

export const btnSizes = {
    [btnSizeKeys.LARGE]: {
        width: 180,
        height: 48,
        unit: 'px',
    },
    [btnSizeKeys.MEDIUM]: {
        width: 152,
        height: 44,
        unit: 'px',
    },
    [btnSizeKeys.SMALL]: {
        width: 100,
        height: 44,
        unit: 'px',
    },
    [btnSizeKeys.FREESIZE]: {
        width: 100,
        height: 100,
        unit: '%',
    },
}
