import React from 'react'

import { btnSizeKeys } from 'components/common/Button'
import Text from 'components/common/Text'

import {
    Container,
    TopSection,
    BottomSection,
    TextWrap,
    Image,
    BottomCurve,
    ScheduleCallBtn,
} from './styles'

const imageProps = {
    src: '/images/misc/schedule_a_call.svg',
    alt: 'Schedule A Call',
}

const curveImageProps = {
    src: '/images/misc/footer-curve.svg',
    alt: 'Curve',
}

const NoMoreContent = ({ handleModal }) => (
    <Container>
        <TopSection>
            <TextWrap>
                <Text level="5" color="#333333">
                    Nothing like experiencing it yourself
                </Text>
            </TextWrap>
            <TextWrap>
                <Text level="3" bold={false} color="#555555">
                    Schedule a call back and discuss real learning!
                </Text>
            </TextWrap>
            <ScheduleCallBtn
                size={btnSizeKeys.LARGE}
                onClick={() => handleModal()}
            >
                Schedule a call back
            </ScheduleCallBtn>
        </TopSection>
        <BottomSection>
            <Image {...imageProps} />
            <BottomCurve {...curveImageProps} />
        </BottomSection>
    </Container>
)

export default NoMoreContent
