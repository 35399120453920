import Cookie from 'utils/cookie'

import { getUserTypeFromToken } from './user'

export const TOKEN_ENV_COOKIE = `TOKEN_${process.env.REACT_APP_CUSTOM_NODE_ENV}`
export const REFRESH_TOKEN_ENV_COOKIE = `REFRESH_TOKEN_${process.env.REACT_APP_CUSTOM_NODE_ENV}`
export const USER_TYPE_COOKIE = `USER_TYPE_${process.env.REACT_APP_CUSTOM_NODE_ENV}`

export const accessTokenHelper = {
    set: token => Cookie.set(TOKEN_ENV_COOKIE, token),
    get: () => Cookie.get(TOKEN_ENV_COOKIE),
    del: () => Cookie.remove(TOKEN_ENV_COOKIE),
}

export const refreshTokenHelper = {
    set: token => Cookie.set(REFRESH_TOKEN_ENV_COOKIE, token),
    get: () => Cookie.get(REFRESH_TOKEN_ENV_COOKIE),
    del: () => Cookie.remove(REFRESH_TOKEN_ENV_COOKIE),
}

export const userTypeHelper = {
    set: token => Cookie.set(USER_TYPE_COOKIE, token),
    get: () => Cookie.get(USER_TYPE_COOKIE),
    del: () => Cookie.remove(USER_TYPE_COOKIE),
}

export const setupAuthTokens = authTokens => {
    const { access_token, refresh_token } = authTokens

    accessTokenHelper.set(access_token)
    refreshTokenHelper.set(refresh_token)
    const userType = getUserTypeFromToken(access_token)
    userTypeHelper.set(userType)
}
