import { combineReducers } from 'redux'

import assignments from 'store/reducers/assignments'
import filters from 'store/reducers/filters'
import profilePage from 'store/reducers/profilePage'
import school from 'store/reducers/school'
import user from 'store/reducers/user'

const rootReducer = combineReducers({
    filters,
    assignments,
    profilePage,
    user,
    school,
})

export default rootReducer
