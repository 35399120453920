import styled from 'styled-components'

import Button from 'components/common/Button'

export const Container = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    background: #f4e5d2;
    padding-top: 20px;
    height: 70vh;
`

export const TopSection = styled.div`
    height: 65%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 0 20px;
`

export const BottomSection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const TextWrap = styled.div`
    display: flex;
    & > span {
        text-align: center;
    }
`

export const Image = styled.img`
    position: absolute;
    bottom: 20px;
    width: 50%;
    z-index: 1;
`

export const BottomCurve = styled.img`
    position: absolute;
    bottom: -20px;
    width: 100%;
`

export const ScheduleCallBtn = styled(Button)`
    background: white;
    width: 100%;
    box-sizing: border-box;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.12);
    & > span {
        color: #ef5c34;
    }
`
