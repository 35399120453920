export const userTypes = {
    STUDENT: 'student',
    TEACHER: 'teacher',
    GUARDIAN: 'parent',
    NONE: 'none',
}

export const genderTypes = {
    male: 'male',
    female: 'female',
}

export const CLOUDINARY_UPLOAD_PRESET = 'signup'
