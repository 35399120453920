import React from 'react'

import { Wrapper, Container } from './styles'

const images = [
    {
        src: '/images/footer/facebook.svg',
        link: 'https://ohstudy.live/oh-facebook',
        alt: 'facebook',
    },
    {
        src: '/images/footer/youtube.svg',
        link: 'https://ohstudy.live/oh-youtube',
        alt: 'youtube',
    },
    {
        src: '/images/footer/instagram.svg',
        link: 'https://ohstudy.live/oh-instagram',
        alt: 'instagram',
    },
    {
        src: '/images/footer/linkedin.svg',
        link: 'https://ohstudy.live/oh-linkedin',
        alt: 'linkedin',
    },
]

const Footer = () => {
    // TODO: change libray for modal scrolling issue on ios
    const isModalOpen = document?.getElementById('custom-modal')
    if (isModalOpen) return null
    return (
        <Container>
            <Wrapper>
                {images.map((image, index) => {
                    return (
                        <a href={image.link} key={index}>
                            <img src={image.src} alt={image.alt} />
                        </a>
                    )
                })}
            </Wrapper>
            <a
                href="https://www.openhouse.study/"
                target="_blank"
                rel="noopener noreferrer"
            >
                <p>openhouse.study</p>
            </a>
        </Container>
    )
}

export default Footer
