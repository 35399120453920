export const studentGrades = [6, 7, 8, 9, 10, 11, 12]

export const slotsDetails = [
    {
        period: 'Morning',
        timeWindow: 'before 12pm',
    },
    {
        period: 'Afternoon',
        timeWindow: '12pm to 6',
    },
    {
        period: 'Evening',
        timeWindow: '6pm - 9pm',
    },
]

export const numberRegex = /^\d+$/

export const phoneRegex = /^[6789]\d{9}$/

export const nameRegex = /^[a-zA-Z\\.\\'\\-]{1,50}(?: [a-zA-Z\\.\\'\\-]{1,50})+/
