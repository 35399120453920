import axios from 'axios'

import { accessTokenHelper } from 'helpers/token'

import config from './config'

const create = ({ baseURL = '', registerInterceptor = false }) => {
    const instance = axios.create({
        baseURL,
        headers: {
            'Content-Type': 'application/json',
        },
    })
    if (registerInterceptor) {
        instance.interceptors.request.use(
            response => {
                // Check if User is authenticated or not
                const token = accessTokenHelper.get()
                if (token)
                    response.headers = {
                        Authorization: `Bearer ${token}`,
                    }
                return response
            },
            error => {
                return error
            }
        )
    }
    return instance
}

export const ohr = create({ baseURL: config.ohApiUrl })
export const ohrProtected = create({
    baseURL: config.ohApiUrl,
    registerInterceptor: true,
})
