import React from 'react'

import { Path, Svg } from './styles'

const Icon = () => {
    const svgProps = {
        viewBox: '0 0 16 16',
        xmlns: 'http://www.w3.org/2000/svg',
    }

    return (
        <Svg {...svgProps}>
            <Path d="M9.00027 15.0005L9.00027 3.83047L13.8803 8.71047C14.2703 9.10047 14.9103 9.10047 15.3003 8.71047C15.6903 8.32047 15.6903 7.69047 15.3003 7.30047L8.71027 0.710468C8.32027 0.320468 7.69027 0.320468 7.30027 0.710468L0.710272 7.30047C0.320272 7.69047 0.320272 8.32047 0.710272 8.71047C1.10027 9.10047 1.73027 9.10047 2.12027 8.71047L7.00027 3.83047L7.00027 15.0005C7.00027 15.5505 7.45027 16.0005 8.00027 16.0005C8.55027 16.0005 9.00027 15.5505 9.00027 15.0005Z" />
        </Svg>
    )
}

export default Icon
