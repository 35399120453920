import React, { useState } from 'react'

import { postWebsiteLead } from 'components/CallSchedular/apis/scheduler'
import Button, { btnSizeKeys } from 'components/common/Button'
import Text from 'components/common/Text'
import NoMoreContent from 'components/NoMoreContent'
import { trackActivityOnHeap } from 'helpers/miscellaneous'
import { checkoutFlowHelper } from 'helpers/storage'
import usePageHistory from 'hooks/usePageHistory'
import { isDesktop } from 'utils/device'
import logger from 'utils/logger'

import ScheduleModal from './components/ScheduleModal'
//helpers
import { slotsDetails, numberRegex } from './constants'
import { getYmdFormat } from './helpers'
//stlyes
import * as SchedularStyle from './styles'

import './style.css' //for customizing modal

const Checkout = ({ type }) => {
    const { navigate } = usePageHistory()
    const [isOpen, setIsOpen] = useState(false)
    const [screen, setScreen] = useState(1)
    const [isSuccess, setIsSuccess] = useState(false)

    //field states
    const [studentName, setStudentName] = useState('')
    const [parentNumber, setParentNumber] = useState('')
    const [gradeSelected, setGradeSelected] = useState('')
    const [slotSelected, setSlotSelected] = useState('')
    const [dateSelected, setDateSelected] = useState('')
    const [fullDateSelected, setFullDateSelected] = useState()
    const [isReceiveUpdates, setIsReceiveUpdates] = useState(true)
    const [isButtonLoading, setIsButtonLoading] = useState(false)

    //active class states
    const [applyActiveClass, setApplyActiveClass] = useState()
    const [applyActiveDate, setApplyActiveDate] = useState()
    const [applyActiveSlot, setApplyActiveSlot] = useState()

    const payload = {
        class: `Class ${gradeSelected}`,
        date: fullDateSelected && getYmdFormat(fullDateSelected),
        name: studentName,
        phone: parentNumber,
        receiveUpdates: isReceiveUpdates,
        receive_updates: isReceiveUpdates,
        standard: `Class ${gradeSelected}`,
        subjects: '',
        timingSlot: slotSelected,
        timing_slot: slotSelected,
        source: 'portfolio',
    }

    const goToStepTwo = () => setScreen(2)

    const goToStepThree = async () => {
        try {
            trackActivityOnHeap('Conversion schedule success')
            setIsButtonLoading(true)
            await postWebsiteLead(payload)
            setIsSuccess(true)
        } catch (error) {
            logger.error('failed to post website lead')
            setIsSuccess(false)
        } finally {
            setIsButtonLoading(false)
            setScreen(3)
        }
    }
    const goToStepOne = () => setScreen(1)

    const resetModal = () => {
        setStudentName('')
        setParentNumber('')
        setGradeSelected('')
        setSlotSelected('')
        setDateSelected('')
        setIsReceiveUpdates(true)
        setIsSuccess(false)
        setApplyActiveClass()
        setApplyActiveDate()
        setApplyActiveSlot()
    }

    const closeModal = () => {
        setIsOpen(false)
        setScreen(1)
        resetModal()
    }
    const showModal = () => {
        setIsOpen(true)
        trackActivityOnHeap('Conversion schedule loaded')
    }

    const gradeHandler = (index, grade) => {
        setGradeSelected(grade)
        setApplyActiveClass(index)
    }
    const dateHandler = (index, date, fulldate) => {
        setDateSelected(date)
        setApplyActiveDate(index)
        setFullDateSelected(fulldate)
    }
    const slotHandler = (index, slot) => {
        setSlotSelected(slot)
        setApplyActiveSlot(index)
    }

    const handleParentNumber = e => {
        const value = e.target.value
        if (!value || numberRegex.test(value)) {
            setParentNumber(value)
        }
    }

    const modalProps = {
        isOpen,
        onClose: closeModal,
        style: {
            height: '100%',
            overflow: 'auto',
            padding: isDesktop ? '50px' : '0',
            position: 'relative',
        },
    }

    const stepOneProps = {
        closeModal,
        studentName,
        setStudentName,
        gradeHandler,
        applyActiveClass,
        parentNumber,
        handleParentNumber,
        setIsReceiveUpdates,
        goToStepTwo,
        gradeSelected,
        isReceiveUpdates,
    }

    const stepTwoProps = {
        goToStepOne,
        dateHandler,
        applyActiveDate,
        slotsDetails,
        slotHandler,
        applyActiveSlot,
        goToStepThree,
        dateSelected,
        slotSelected,
        isButtonLoading,
    }

    const stepThreeProps = {
        closeModal,
        isSuccess,
    }

    const scheduleModalProps = {
        modalProps,
        stepOneProps,
        stepTwoProps,
        stepThreeProps,
        screen,
    }

    const handleCheckoutFlow = () => {
        navigate('/auth')
        checkoutFlowHelper.set(true)
    }

    if (type === 'noMoreContent')
        return (
            <>
                <ScheduleModal {...scheduleModalProps} />
                <NoMoreContent handleModal={handleCheckoutFlow} />
            </>
        )
    else if (type === 'learnWithMe') {
        return (
            <>
                <ScheduleModal {...scheduleModalProps} />
                <SchedularStyle.LearnWithMeButton onClick={showModal}>
                    Learn with me
                </SchedularStyle.LearnWithMeButton>
            </>
        )
    } else {
        return (
            <SchedularStyle.StaticCardContainer>
                <SchedularStyle.StaticRightContents>
                    <Text color="#333" level="3">
                        Nothing like experiencing it yourself
                    </Text>
                    <Button
                        onClick={handleCheckoutFlow}
                        size={btnSizeKeys.MEDIUM}
                    >
                        Schedule a call
                    </Button>
                </SchedularStyle.StaticRightContents>
                <SchedularStyle.StaticLeftContents>
                    <img src="/images/static.svg" alt="static" />
                </SchedularStyle.StaticLeftContents>
                <ScheduleModal {...scheduleModalProps} />
            </SchedularStyle.StaticCardContainer>
        )
    }
}

export default React.memo(Checkout)
