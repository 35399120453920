export default {
    brand: {
        orange: '#EF5C34',
        dark: '#261E2C',
        white: '#E5E5E5',
        black: '#323232',
        blue: '#58b7e6',
    },
    blue: '#3e4193',
    white: '#FFFFFF',
    tertiaryWhite: '#FFFFFF61',
    black: '#000',
    transparent: 'transparent',
    grey: '#636363',
    darkGrey: '#4f4f4f',
}
