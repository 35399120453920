import {
    INITIALIZE_ASSIGNMENTS,
    UPDATE_ASSIGNMENTS_LIST,
    APPLY_ASSIGNMENT_FILTERS,
    SET_IS_FETCHING_ASSIGNMENTS,
    GET_NEWSLETTERS,
} from '../types/assignments'

export const initializeAssignments = payload => ({
    type: INITIALIZE_ASSIGNMENTS,
    payload,
})

export const updateAssignments = payload => ({
    type: UPDATE_ASSIGNMENTS_LIST,
    payload,
})

export const getNewsletterSuccesss = payload => ({
    type: GET_NEWSLETTERS,
    payload,
})

export const applyFilters = payload => ({
    type: APPLY_ASSIGNMENT_FILTERS,
    payload,
})

export const isFetchingAssignments = payload => ({
    type: SET_IS_FETCHING_ASSIGNMENTS,
    payload,
})
