import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
   * {
       -webkit-text-size-adjust: none;
       text-size-adjust: none;
       -webkit-tap-highlight-color: transparent;
       box-sizing: border-box;
       input[type="number"] {
          -moz-appearance: textfield;
        }
        input[type="number"]::-webkit-inner-spin-button, 
        input[type="number"]::-webkit-outer-spin-button { 
          -webkit-appearance: none; 
          margin: 0; 
        }
    }

    @font-face {
        font-family: Avenir;
        src: url('/font/AvenirNextRoundedStd-Reg.ttf') format('truetype');
    }

    @font-face {
        font-family: AvenirBold;
        font-style: 'Demi';
        src: url('/font/AvenirNextRoundedStd-Demi.ttf') format('truetype');
    }

    @font-face {
        font-family: AvenirItalic;
        src: url('/font/AvenirNextRoundedStd-MedIt.ttf') format('truetype');
    }

    @font-face {
        font-family: NunitoSans;
        src: url('/font/NunitoSans-Regular.ttf') format('truetype');
    }
    
    @font-face {
        font-family: NunitoBold;
        src: url('/font/NunitoSans-Bold.ttf') format('truetype');
    }

    body {
        margin: 0;
        padding: 0;
        font-family: 'Avenir', 'AvenirBold', 'AvenirItalic';
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        cursor: default;
        background: ${props => props.theme.styles.body};
    }

    /* prevent images from being selectable */
    img {
        user-select: none;
    }

    @media (min-width: 992px) {
        #root {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    /* fix iphone input field zoomed in issue */
    @media screen and (max-width: 768px) {
        input[type='text'],
        input[type='tel'] {
            font-size: 16px;
        }
    }
`

export default GlobalStyles
