import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

import Button, { btnSizeKeys } from 'components/common/Button'
import Text from 'components/common/Text'
import colors from 'themes/colors'

import { Container, Image } from './styles'

const imageProps = {
    src: '/images/error/error.gif',
    alt: 'Error Icon',
}

const ErrorPage = ({ text, buttonText }) => {
    return (
        <Container>
            <Image {...imageProps} />
            <Text color={colors.brand.orange} level="6">
                {text}
            </Text>
            <Link to="/">
                <Button size={btnSizeKeys.FREESIZE}>{buttonText}</Button>
            </Link>
        </Container>
    )
}

ErrorPage.defaultProps = {
    text: 'the page you are looking for does not exist',
    buttonText: 'visit our homepage',
}

ErrorPage.propTypes = {
    text: PropTypes.string,
    buttonText: PropTypes.string,
}

export default ErrorPage
