import { assignmentFilterKeys } from 'constants/apiFilterkeys'

import {
    SET_CLUBS,
    ADD_FILTER,
    REMOVE_FILTER,
    INITIALIZE_FILTERS,
    UPDATE_SELECTED_FILTERS,
    SET_SEARCH_TEXT,
} from '../types/filters'

const initialState = {
    loading: true,
    data: null,
    selected: null,
    clubs: null,
    search: '',
}
const { USER_ID, SCHOOL_UUID } = assignmentFilterKeys

const initializeSelectedProp = (obj = {}) => {
    /* initilize selected property */
    const keys = Object.keys(obj)
    const selected = {}
    keys.forEach(key => {
        if (![USER_ID, SCHOOL_UUID].includes(key)) selected[key] = []
    })

    return selected
}

const FilterReducer = (state = initialState, action) => {
    let key, val

    switch (action.type) {
        case INITIALIZE_FILTERS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                selected: initializeSelectedProp(action.payload),
            }

        case SET_CLUBS:
            return {
                ...state,
                clubs: action.payload,
            }

        case SET_SEARCH_TEXT:
            return {
                ...state,
                search: action.payload,
            }

        case ADD_FILTER:
            key = action.payload.key
            val = action.payload.val

            return {
                ...state,
                selected: {
                    ...state.selected,
                    [key]: [val, ...state.selected[key]],
                },
            }

        case REMOVE_FILTER:
            key = action.payload.key
            val = action.payload.val

            const subjectsOrClubs = uuid => !val.includes(uuid)
            const categoriesOrClasses = _val => _val !== val

            const filterToPerform = Array.isArray(val)
                ? subjectsOrClubs
                : categoriesOrClasses

            const newVal = val
                ? state.selected[key].filter(filterToPerform)
                : []

            return {
                ...state,
                selected: {
                    ...state.selected,
                    [key]: newVal,
                },
            }

        case UPDATE_SELECTED_FILTERS:
            const selected = action.payload
                ? action.payload
                : initializeSelectedProp(state.selected)
            return {
                ...state,
                selected,
            }

        default:
            return state
    }
}

export default FilterReducer
