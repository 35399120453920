import {
    SET_USER_AUTENTICATED,
    SET_USER_INFO,
    SET_USER_AUTH_TOKENS,
    SET_USER_TYPE,
    REMOVE_COOKIE,
    LOGOUT,
} from 'store/types/user'

export const setAuth = payload => ({
    type: SET_USER_AUTENTICATED,
    payload,
})

export const setUserInfo = payload => ({
    type: SET_USER_INFO,
    payload,
})

export const setTokens = payload => ({
    type: SET_USER_AUTH_TOKENS,
    payload,
})

export const setUserType = payload => ({
    type: SET_USER_TYPE,
    payload,
})

export const removeCookies = () => ({
    type: REMOVE_COOKIE,
})

export const logout = () => ({
    type: LOGOUT,
})
