import { SET_SCHOOL, SET_STANDARDS, SET_BOARDS } from '../types/school'

const initialState = {
    standards: null,
    boards: null,
    school: null,
}

const schoolReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SCHOOL:
            return {
                ...state,
                school: action.payload,
            }
        case SET_STANDARDS:
            return {
                ...state,
                standards: action.payload,
            }
        case SET_BOARDS:
            return {
                ...state,
                boards: action.payload,
            }

        default:
            return state
    }
}

export default schoolReducer
