import React from 'react'

import { Container } from './styles'

const FailureAnim = () => {
    return (
        <Container>
            <svg
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32ZM4.54126 32C4.54126 47.165 16.835 59.4587 32 59.4587C47.165 59.4587 59.4587 47.165 59.4587 32C59.4587 16.835 47.165 4.54126 32 4.54126C16.835 4.54126 4.54126 16.835 4.54126 32Z"
                    fill="#DE6645"
                />
                <path
                    d="M34.95 42.7925C35.65 43.4823 36 44.3287 36 45.3319C36 46.3352 35.65 47.1973 34.95 47.9184C34.2792 48.6395 33.4625 49 32.5 49C31.5667 49 30.75 48.6551 30.05 47.9654C29.35 47.2757 29 46.4292 29 45.426C29 44.4228 29.3354 43.5606 30.0063 42.8396C30.7063 42.1185 31.5375 41.758 32.5 41.758C33.4625 41.758 34.2792 42.1028 34.95 42.7925ZM29.875 35.5035V18.1978C29.875 17.0065 30.1521 16.1757 30.7063 15.7054C31.2604 15.2351 31.8583 15 32.5 15C33.1417 15 33.7396 15.2351 34.2938 15.7054C34.8479 16.1757 35.125 17.0065 35.125 18.1978V35.5035C35.125 36.6948 34.8479 37.5256 34.2938 37.9958C33.7396 38.4661 33.1417 38.7012 32.5 38.7012C31.8583 38.7012 31.2604 38.4661 30.7063 37.9958C30.1521 37.5256 29.875 36.6948 29.875 35.5035Z"
                    fill="#DE6645"
                />
            </svg>
        </Container>
    )
}

export default FailureAnim
