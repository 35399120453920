import { Checkbox } from '@openhouse-engineers/rrc'
import React, { useState } from 'react'

import {
    phoneRegex,
    studentGrades,
    nameRegex,
} from 'components/CallSchedular/constants'
import * as SchedularStyle from 'components/CallSchedular/styles'
import Button, { btnSizeKeys, btnTypes } from 'components/common/Button'
import Text from 'components/common/Text'
import { trackActivityOnHeap } from 'helpers/miscellaneous'

const StepOne = ({
    closeModal,
    studentName,
    setStudentName,
    gradeHandler,
    applyActiveClass,
    parentNumber,
    handleParentNumber,
    setIsReceiveUpdates,
    goToStepTwo,
    gradeSelected,
    isReceiveUpdates,
}) => {
    const [isPhoneValid, setIsPhoneValid] = useState(
        phoneRegex.test(parentNumber)
    )
    const [isNamevalid, setIsNameValid] = useState(
        studentName ? nameRegex.test(studentName) : false
    )
    const [phoneError, setPhoneError] = useState('')
    const validatePhone = e => {
        setIsPhoneValid(false)
        handleParentNumber(e)
        if (e.target.value.length >= 10 && phoneRegex.test(e.target.value)) {
            setIsPhoneValid(true)
        } else {
            setIsPhoneValid(false)
            setPhoneError('Please enter valid 10 digit phone number')
        }
    }
    const validateName = e => {
        setStudentName(e.target.value)
        setIsNameValid(false)
        if (nameRegex.test(e.target.value)) setIsNameValid(true)
        else setIsNameValid(false)
    }

    const hasError = !isPhoneValid || !gradeSelected || !isNamevalid
    return (
        <SchedularStyle.Conatiner>
            <SchedularStyle.Top>
                <button onClick={closeModal}>
                    <img
                        src="/images/checkout/back_arrow.svg"
                        alt="back arrow"
                    />
                </button>
            </SchedularStyle.Top>
            <SchedularStyle.ModalContents>
                <SchedularStyle.Form>
                    <SchedularStyle.Header>
                        <Text color="#DE6645" level="1">
                            Step 1 of 2
                        </Text>
                        <Text level="5">Please enter contact details </Text>
                        <Text level="3" bold={false}>
                            Rest assured your details are safe with us
                        </Text>
                    </SchedularStyle.Header>
                    <SchedularStyle.InputContainer>
                        <Text level="2" bold={false}>
                            Student Name
                        </Text>
                        <input
                            type="text"
                            placeholder="Student’s full name"
                            value={studentName}
                            onChange={e => validateName(e)}
                        />
                        {studentName && !isNamevalid && (
                            <Text color="#DE6645" size="2" bold={false}>
                                Enter valid name
                            </Text>
                        )}
                    </SchedularStyle.InputContainer>
                    <Text level="2" bold={false}>
                        Student Grade
                    </Text>
                    <SchedularStyle.ButtonList>
                        {studentGrades.map((grade, index) => {
                            return (
                                <Button
                                    key={index}
                                    size={btnSizeKeys.SMALL}
                                    type={btnTypes.SECONDARY}
                                    onClick={() => gradeHandler(index, grade)}
                                    className={
                                        applyActiveClass === index
                                            ? 'activeButton'
                                            : ''
                                    }
                                >
                                    {grade}
                                </Button>
                            )
                        })}
                    </SchedularStyle.ButtonList>
                    <SchedularStyle.InputContainer>
                        <Text level="2" bold={false}>
                            Parent’s WhatsApp number
                        </Text>
                        <input
                            type="tel"
                            pattern="[0-9]*"
                            placeholder="Enter parent’s WhatsApp number"
                            value={parentNumber}
                            onChange={e => validatePhone(e)}
                            maxLength={10}
                        />
                        {!isPhoneValid && (
                            <Text color="#DE6645" size="2" bold={false}>
                                {phoneError}
                            </Text>
                        )}
                    </SchedularStyle.InputContainer>
                    <SchedularStyle.CheckboxContainer>
                        <Checkbox
                            checked={isReceiveUpdates}
                            selected={isReceiveUpdates}
                            onClick={() => {
                                trackActivityOnHeap(
                                    'Reaceive whatsapp update clicked'
                                )
                                setIsReceiveUpdates(!isReceiveUpdates)
                            }}
                        />
                        <Text level="2" bold={false}>
                            receive notifications on WhatsApp
                        </Text>
                    </SchedularStyle.CheckboxContainer>
                </SchedularStyle.Form>
                <SchedularStyle.ButtonContainer>
                    <Button
                        onClick={goToStepTwo}
                        size={btnSizeKeys.FREESIZE}
                        disabled={hasError}
                    >
                        Next
                    </Button>
                </SchedularStyle.ButtonContainer>
            </SchedularStyle.ModalContents>
        </SchedularStyle.Conatiner>
    )
}

export default StepOne
