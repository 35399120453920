import { lazy } from 'react'

const Auth = lazy(() => import('components/Auth'))
const Assignment = lazy(() => import('pages/Assignment'))
const ErrorPage = lazy(() => import('pages/Error'))
const Home = lazy(() => import('pages/Home'))
const InstructionPage = lazy(() => import('pages/Instructions'))
const ProfilePage = lazy(() => import('pages/Profile'))
const SchoolPage = lazy(() => import('pages/School'))
const Newsletter = lazy(() => import('pages/Newsletter'))

export const routes = [
    { path: '/', exact: true, component: Home },
    {
        path: '/error',
        exact: true,
        component: ErrorPage,
    },
    {
        path: '/:userId/assignments/:assignmentId',
        exact: true,
        component: Assignment,
    },
    {
        path: '/profile/:userId/instructions/:instructionId',
        exact: true,
        component: InstructionPage,
    },
    {
        path: '/profile/:userId',
        exact: true,
        component: ProfilePage,
    },
    {
        path: '/auth',
        exact: true,
        component: Auth,
    },
    {
        path: '/not-found',
        exact: true,
        component: ErrorPage,
    },
    {
        path: '/newsletter',
        exact: true,
        component: Newsletter,
    },
    {
        path: '/:school',
        exact: true,
        component: SchoolPage,
    },
    {
        path: '*',
        component: ErrorPage,
    },
]
