import { keyframes, css } from 'styled-components'

import { themes } from 'themes/constants'

export const shimmerKeyframes = keyframes`
    0% {
        background-position: -1000px 0;
    }
    100% {
        background-position: 1000px 0;
    }
`

export const shimmer = css`
    animation: ${shimmerKeyframes} 2s infinite linear;

    ${props =>
        props.theme.name === themes.DARK
            ? `
    
            background: #50535a;
            background-image: linear-gradient(
                to right,
                #50535a 0%,
                #656871 20%,
                #50535a 40%,
                #50535a 100%
            );
            background-repeat: no-repeat;
            background-size: 800px 800px;
            `
            : `
                background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
                background-size: 1000px 100%;
            `}
`
