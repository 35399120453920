import React, { Suspense } from 'react'
import { Switch, Route } from 'react-router-dom'

import SiteSkeleton from 'components/SiteSkeleton'

import { routes } from './constants/routes'

const Routes = () => (
    <Suspense fallback={<SiteSkeleton />}>
        <Switch>
            {routes.map((route, index) => (
                <Route key={index} {...route} />
            ))}
        </Switch>
    </Suspense>
)

export default Routes
